.reseller-coaching-navbar {
  background: white;
  box-shadow: 0 2px 2px rgb(219, 219, 219);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 15px;

  .navbar-link {
    margin: 0 40px;
  }
}

.button-navbar {
  height: 60px;
  width: auto;
}

.down-navbar-button {
  color: white !important;
  position: absolute;
  right: 10px;
  font-size: 30px;
}

@media screen and (max-width: 1150px) {
  .reseller-coaching-navbar {
    .logo-navbar {
      display: none;
    }
    .navbar-link {
      margin: 0 25px;
    }
  }
}
