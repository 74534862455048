.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.btn {
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  border: 2px solid rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  width: 300px;
  cursor: pointer;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.ant-modal.modal-import-csv {
  min-width: 90%;
}

.red-select {
  .ant-select-selector {
    border: 2px solid red !important;
  }
}

.ant-input.red-select {
  border: 2px solid red;
}

.anticon.anticon-edit.edit-image-absolute {
  position: absolute;
  font-size: 50px;
  color: white;
}

.edit-image-import .ant-upload img {
  filter: grayscale(40%);
  filter: blur(1px) grayscale(40%);
}

.card-tuto-import {
  width: 320px;
  padding: 12px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 2px 2px 6px rgb(196, 196, 196);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.icon-in-case {
  width: auto;
  height: 50px;
}

.step-1-import-csv {
  background-image: url("../../../images/import-csv-illustration.svg");
  background-size: 40%;
  background-position: bottom right;
  background-repeat: no-repeat;
}

.import-csv-modal-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: -1px 0px 3px rgb(206, 206, 206);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(250, 250, 250);
  width: 520px;
  min-width: 90%;
  padding: 20px 5vw;
}

@media screen and (max-width: 1150px) {
  .btn {
    width: auto;
  }
  .import-csv-modal-footer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    height: 160px;
  }
}
