.filter-label{
    background-color: rgb(154, 198, 255);
    border-radius: 6px;
    padding: 6px 10px;
    color: white;
    font-weight: bold;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}