#market-sub-category {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 40px;

  img {
    width: 20%;
    margin-right: 30px;
    min-width: 50px;
    max-height: 120px;
  }
  .column {
    width: 65%;
  }
}

.z-index-1000 {
  z-index: 1000 !important;
  position: absolute;
  top: 0;
}

#import-product-button-little {
  &:active {
    animation: importButtonAnimation 0.5s;
  }
}

@keyframes importButtonAnimation {
  from {
    box-shadow: 1px 1px 3px rgb(199, 199, 199);
    font-size: 16px;
  }
  to {
    box-shadow: 3px 3px 6px rgb(199, 199, 199);
    font-size: 28px;
  }
}

.marketplace-suggestion {
  .product-suggestion-card {
    overflow: hidden !important;
    height: 520px;
  }
}

.marketplace-suggestion .setup-progress {
  position: relative;
  left: auto;
  bottom: auto;
  top: auto;
  right: auto;
  box-shadow: none;
  margin-bottom: 20px;
  border: 1px solid rgb(238, 238, 238);
  margin-left: 20px;
}

#search-products {
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  .ant-tabs-tab.ant-tabs-tab-active {
    background: #ffbb7a !important;
    color: white !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }
  .ant-input-affix-wrapper.marketplace {
    width: 80%;
    max-width: 1000px;
    margin-bottom: 30px;
  }

  .grey-box {
    padding: 2px 10px;
    background: rgb(182, 182, 182);
    color: white;
    cursor: pointer;
    border-radius: 6px;
    margin: 4px;
    box-shadow: 1px 1px 2px rgb(179, 179, 179);
  }
  .orange-box {
    padding: 2px 10px;
    background: rgb(252, 165, 65);
    color: white;
    cursor: pointer;
    border-radius: 6px;
    margin: 4px;
    box-shadow: inset 1px 1px 4px rgb(223, 153, 74), inset -25px -25px 3px rgb(255, 195, 126);
  }
}

#deleted-suggestion-product-card {
  overflow: hidden !important;
  height: 0px;
  animation: deletedProduct 0.3s;
}

@keyframes deletedProduct {
  0% {
    height: 450px;
  }
  100% {
    height: 0;
  }
}

#display-suggestion-product-card {
  overflow: hidden !important;
  height: 0px;
  animation: displayProduct 0.4s;
}

@keyframes displayProduct {
  0% {
    height: 0;
  }
  100% {
    height: 450px;
  }
}

@media screen and (max-width: 1150px) {
  #search-products {
    .ant-input-affix-wrapper.marketplace {
      width: 100%;
      max-width: 1000px;
      margin-bottom: 30px;
    }
  }
  .marketplace-suggestion .container-card-product {
    width: 95vw;
    max-width: 330px;
  }

  #market-sub-category {
    flex-direction: column;

    img {
      width: 50%;
      max-width: 150px;
      max-height: none;
    }

    .column {
      width: 100%;
      margin-top: 25px;
    }
  }
}
