.blog h1 {
  font-size: 26px !important;
  font-weight: 800;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif !important;
}

.blog h2 {
  font-size: 22px;
  font-weight: 800;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif !important;
  color: #5a5a5a;
}

.blog h3 {
  font-size: 16px;
  font-weight: 700;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif !important;
  color: #707070;
  text-transform: uppercase;
}

.blog .ant-anchor-wrapper {
  max-width: 100%;
}

.blog .ant-anchor-link-title {
  white-space: normal;
}
.blog p {
  font-size: 16px;
}

.blog li {
  font-size: 16px;
}


.blog .cover {
  width: 35%;
  margin-left: 5%;
  min-width: 300px;
}

.blog .header-container {
  width: 45%;
  margin-right: 10%;
  min-width: 300px;
  max-width: 500px;
}

.blog-tag {
  font-weight: 600;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  background: #d8d8d8;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  margin-left: 0;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.blog-page-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.blog-page-suggestions-container {
  width: 25%;
  margin-top: 40px;
}

.blog-page-articles-container {
  width: 70%;
  display: flex;
  flex-wrap: wrap;
}

.blog .ant-anchor-link-title {
  text-align: start;
  color: #70c4f3;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 950px) {
  .blog .cover {
    display: none;
  }

  .blog-page-suggestions-container {
    display: none;
  }

  .blog-page-articles-container {
    width: 100%;
  }
}