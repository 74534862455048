@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

.blog-h1 {
    margin-bottom: 20px; 
    margin-top: 20px;
    font-size: 40px; 
    font-weight: 900; 
    font-family: 'Montserrat'; 
    line-height: 45px;
  }
  .blog-h2 {
    margin-bottom: 20px; 
    margin-top: 20px;
    font-size: 25px; 
    font-weight: 800; 
    color: rgb(88, 88, 88); 
    font-family: 'Montserrat'; 
    line-height: 35px;
  }
  .blog-h3 {
    margin-bottom: 20px; 
    margin-top: 20px;
    font-size: 20px; 
    font-weight: 700; 
    color: rgb(88, 88, 88); 
    font-family: 'Roboto'; 
    line-height: 25px;
  }
  .blog-h4 {
    margin-bottom: 20px; 
    margin-top: 20px;
    font-size: 20px; 
    font-weight: 500; 
    color: rgb(88, 88, 88); 
    font-family: 'Roboto'; 
    line-height: 25px;
  }
  .blog-page {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  }