.sourcing-label-processing {
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 2px 20px;
  background-color: #6fd97ad9;
  margin-top: 10px;
}

.sourcing-label-finished {
  border-radius: 4px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  padding: 2px 20px;
  background-color: #ffc188d5;
  margin-top: 10px;
}
