.welcome-page {
  background-image: url(../../../images/welcomeForm/background.svg);
  background-size: cover;
  background-repeat: no-repeat;

  .back-black {
    background: rgba(0, 0, 0, 0.288);
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 20vw;
    margin-top: 60px;
  }
  .form {
    background: white;
    border-radius: 4px;
    box-shadow: 1px 1px 3px rgb(129, 129, 129);
    padding: 30px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 800px;
  }
  .normal-text {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
  }
  .illustration {
    height: 147px;
    margin-right: 40px;
  }
  .skip {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: #f5f5f5;
    border-radius: 6px;
    padding: 7px 15px;
    cursor: pointer;
    top: 0;
  }
}

@media screen and (max-width: 1150px) {
  .welcome-page {
    .skip{
      position: relative;
      width: fit-content;
    }
    .back-black {
      padding: 15px;
      justify-content: flex-start;
      padding-bottom: 100px;
    }
    .form {
      width: 95vw;
    }
    .illustration {
      display: none;
    }
    .title-bold.width-100.center {
      text-align: start;
    }
  }
}
