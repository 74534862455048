.part {
  width: 40%;
  min-height: 60px;
}

.shopify-variant-card {
    background: rgb(247, 247, 247);
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
}
.ant-select-item-option-content {
  display: flex;
  align-items: center;
}
.separator {
  color: grey;
  margin: 0 10px;
}

.mapping-variant {
  margin-bottom: 15px;

  .arrow {
    width: 20px;
  }

  .ant-select .ant-select-selector {
    height: 60px !important; /* Changer la hauteur selon vos besoins */
    display: flex !important;
    align-items: center !important; /* Centrer le contenu verticalement */
    justify-content: center !important; /* Centrer le contenu horizontalement */
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1150px) {
  .mapping-variant {
    flex-direction: column !important;
  }
  .part {
    width: 100%;
    max-width: 400px;
    margin: 10px 0px;
  }
  .title-variant-mapping{
    
  }
}
