.setup-progress {
  border-radius: 8px;
  box-shadow: 1px 1px 4px rgb(189, 189, 189);
  background: white;
  position: fixed;
  bottom: 20px;
  right: 35px;
  z-index: 1000;
  padding: 15px 25px;
  width: 390px;
  max-width: 85vw;
  .ant-progress-inner {
    height: 15px;
  }
  .ant-progress-bg {
    height: 100% !important;
  }
}
