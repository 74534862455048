.card-imported-process {
  background: white;
  padding: 20px 40px;
  border-radius: 8px;
  border: 1px solid rgb(165, 165, 165);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .refresh-button {
    box-shadow: 1px 1px 1px rgb(218, 218, 218);
    border: 1px solid rgb(187, 187, 187);
    padding: 6px 10px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    color: #a97af5;
    cursor: pointer;

    font-family: "Montserrat", sans-serif !important;
  }

  .ant-progress-bg {
    background: #b18bee !important;
  }
}

.card.monitoring {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 35%;
  margin-left: 15px;
  height: 190px;
}

.cost-evolution .column {
  margin: 0 20px;
  min-width: 150px;
}

.cost-evolution .title {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
}

.cost-evolution .text {
  margin-bottom: 7px;
}

.cost-evolution {
  width: 100%;
  justify-content: space-between;
  overflow: scroll;
}

.inventory.selector {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b9bfe0;
  border-radius: 6px;
  background: rgb(255, 255, 255);
  width: fit-content;

  .row-1 {
    margin-right: 10px;
    border-right: 1px solid rgb(158, 158, 158);
    padding: 10px 15px;
    padding-right: 25px;
  }
  .row-2 {
    padding: 10px 15px;
    cursor: pointer;
    width: 120px;
  }
}

.ant-dropdown-menu.inventory-dropdown {
  background: rgb(255, 255, 255);
  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid rgb(158, 158, 158);
  top: 6px;
  left: -25px;
  width: 127px;
}

@media screen and (max-width: 1150px) {
  .see-product-page-link {
    display: none !important;
  }

  .ant-modal-header {
    min-height: 55px;
  }

  .inventory.selector {
    width: 100%;

    .row-1 {
      width: 50%;
      display: flex;
      justify-content: center;
    }
    .row-2 {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }
  .card-imported-process {
    margin-bottom: 40px;

    .row {
      flex-direction: column;
      align-items: flex-start;
    }
    button {
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
  .card.monitoring {
    margin-top: 15px;
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    margin-left: 0;
  }
}
