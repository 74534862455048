.reseller-coaching {
  overflow-x: hidden;
  .banner-1 {
    background: linear-gradient(
      47deg,
      rgba(254, 244, 212, 0.5200674019607843) 7%,
      rgba(255, 255, 255, 1) 41%,
      rgba(89, 216, 245, 0.10830269607843135) 73%
    );
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .left-column {
    width: 30%;
    margin-right: 30px;
    min-width: 300px;
    max-width: 350px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .right-column {
    width: 30%;
    margin-left: 30px;
    min-width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .black-banner {
    width: 100vw;
    background: #1f1f1f;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    position: relative;

    .arrow-1 {
      position: absolute;
      top: 0px;
      height: 160px;
      left: calc(50vw - 460px);
    }
  }
  .banner-3 {
    background-image: url("../../../images/resellerCoaching/background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .right-column {
      margin-left: 20px;
      width: 300px;
    }
  }
  .banner-3 .card {
    background: #f9ffff;
    border-radius: 11px;
    box-shadow: 1px 1px 4px rgb(209, 209, 209);
    padding: 50px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 30px;
  }
  .illustration-2 {
    width: 300px;
    margin-right: 20px;
  }
  .check-img {
    width: 20px;
    margin-right: 15px;
  }
  .banner-4 {
    .right-column {
      max-width: 420px;
      width: 420px;
    }
  }
  .banner-5 {
    .card {
      background: #fefbf5;
      border-radius: 8px;
      box-shadow: 1px 1px 4px rgb(209, 209, 209);
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-top: 30px;
    }
    .icon {
      width: 50px;
      margin-bottom: 10px;
    }
  }
  .banner-6 {
    background: linear-gradient(
      47deg,
      rgba(254, 244, 212, 0.5508797268907564) 7%,
      rgba(255, 255, 255, 1) 41%,
      rgba(49, 175, 204, 0.1727284663865546) 73%
    );
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px;
    margin: 40px 0;
  }
  .black-banner.roadmap {
    flex-direction: column;
    .roadmap1 {
      width: 80vw;
      max-width: 800px;
      justify-content: flex-start;
      margin-top: 50px;
    }
    .roadmap2 {
      width: 80vw;
      max-width: 800px;
      justify-content: center;
      position: relative;
      height: 100px;
      margin-bottom: 50px;
    }
    .roadmap-3 {
      position: absolute;
      left: 200px;
      bottom: 0;
    }
    .roadmap-4 {
      position: absolute;
      right: -20px;
    }
    .illustration8 {
      width: 80vw;
      max-width: 800px;
      margin: 20px 0;
    }
    .title {
      width: 80vw;
      max-width: 800px;
      text-align: start;
    }
  }

  .illustration9 {
    width: 400px;
    margin-left: 100px;
    pointer-events: none;
  }

  .review-card {
    border-radius: 8px;
    box-shadow: 1px 1px 2px rgb(197, 197, 197);
    background: #f8f8f8;
    padding: 15px 30px;
    margin: 15px;
    width: 320px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .reviews {
    width: 70vw;
    max-width: 800px;
    justify-content: center;
  }

  .back-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
    height: 100px;
    margin-bottom: 100px;
  }
  .arrow-1-2 {
    position: absolute;
    top: 0;
    width: 80px;
    left: calc(50vw - 230px);
  }
  .arrow-2-2 {
    position: absolute;
    bottom: 0;
    width: 80px;
    right: calc(50vw - 230px);
  }
}

.reseller-coaching.advanced {
  .banner-1 {
    background: linear-gradient(
      47deg,
      rgba(254, 212, 212, 0.5508797268907564) 7%,
      rgba(255, 255, 255, 1) 41%,
      rgba(49, 175, 204, 0.1727284663865546) 73%
    );
  }
  .illustration-2 {
    width: 150px;
  }
}

.reseller-coaching.pro {
  .banner-1 {
    background: linear-gradient(
      47deg,
      rgba(246, 234, 169, 0.33519345238095233) 7%,
      rgba(255, 255, 255, 1) 41%,
      rgba(49, 175, 204, 0.1727284663865546) 73%
    );
    padding-bottom: 40px;
  }
  .illustration-2 {
    width: 150px;
  }
}

@media screen and (max-width: 1150px) {
  .reseller-coaching {
    .communicate {
      flex-direction: column;
    }
    .banner-3 .card {
      flex-direction: column;
    }
    .illustration-2 {
      margin-right: 0;
      margin-bottom: 15px;
    }
    .banner-4 .left-column {
      display: none;
    }
    .banner-4 {
      padding: 20px;
    }
    .banner-5 .row.start {
      flex-direction: column;
    }
    .banner-5 .right-column {
      margin-left: 0;
    }
    .banner-5 .left-column {
      margin-right: 0;
    }
    .banner-6 .row.center {
      flex-direction: column-reverse;
    }
    .banner-6 .right-column {
      margin-left: 0;
      margin-bottom: 30px;
    }
    .banner-6 .left-column {
      margin-right: 0;
    }
    .arrow-1 {
      display: none;
    }
    .banner-1 .right-column {
      display: none;
    }
    .black-banner {
      padding: 30px;
    }
    .roadmap1 {
      flex-direction: column;
      width: 100vw !important;
      margin-top: 10px;
    }
    .roadmap2 {
      flex-direction: column;
      width: 100vw !important;
      margin-top: 20px;
    }
    .roadmap1 {
      position: relative !important;
      left: auto !important;
      right: auto !important;
      top: auto;
      bottom: auto;
      width: 90vw !important;
      margin-right: 0 !important;
      margin-left: 0;
      margin: 20px;
      margin: 20px !important;
    }
    .roadmap2 {
      position: relative !important;
      left: auto !important;
      right: auto !important;
      top: auto;
      bottom: auto;
      width: 90vw !important;
      margin-right: 0 !important;
      margin-left: 0;
      margin: 20px;
      margin: 20px !important;
    }
    .roadmap-3 {
      position: relative !important;
      left: auto !important;
      right: auto !important;
      top: auto;
      bottom: auto;
      width: 90vw !important;
      margin-right: 0 !important;
      margin-left: 0;
      margin: 20px;
      margin: 20px !important;
    }
    .roadmap-4 {
      position: relative !important;
      left: auto !important;
      right: auto !important;
      top: auto;
      bottom: auto;
      width: 90vw !important;
      margin-right: 0 !important;
      margin-left: 0;
      margin: 20px;
      margin: 20px !important;
    }
    .roadmap .title {
      text-align: center !important;
    }
    .illustration8 {
      display: none;
    }
    .reviews {
      width: 95vw;
    }
    .row.start.review {
      width: 90vw;
      flex-direction: column;
    }
    .illustration9 {
      margin-left: 0;
      width: 100%;
    }
    .academy {
      width: 90vw;
      margin: 0 !important;
      margin-right: 0 !important;
      margin-top: 60px !important;
      margin-bottom: 40px !important;
      margin-left: 5vw !important;
    }
    .banner-5 .card {
      width: 95vw;
    }
  }
}
