.blog-tag-menu {
  width: 100%;
  border-bottom: 1px solid rgb(182, 182, 182);
  min-height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.blog-article-card {
  width: 320px;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
  margin-top: 50px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
  &:hover {
    animation: transform 0.5s ease forwards;
  }
}
.row-wrap-blog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
}

.suggestion-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}

@keyframes transform {
  from {
  }
  to {
    transform: scale(1.03);
  }
}

.blog-article-card-img {
  width: 100%;
  object-fit: cover;
  height: 140px;
}

.course-sponso-container {
  width: 100%;
  padding: 30px;
  background: #f4f4f4;
  border-radius: 10px;
  margin-top: 20px;
  height: fit-content;
}

.course-sponso-image-container {
  width: 30%;
  margin: 0 30px 30px 0;
  min-width: 200px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.course-sponso-image {
  width: 100%;
  object-fit: cover;
}

.course-sponso-image-title {
  color: white;
  font-size: 15px;
  font-weight: bold;
  position: absolute;
  bottom: 22px;
}

.course-sponso-image-price {
  color: white;
  font-size: 15px;
  position: absolute;
  bottom: 3px;
}

.course-sponso-content {
  width: 60%;
  min-width: 210px;
}
