.coaching-dashboard {
  .card {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    border-radius: 11px;
    padding: 30px 40px;
  }
}
