.etsy-label {
  background: #f6f6f6;
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 15px;
  width: 100%;
  color: #585858;
  padding-left: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 25px;
  font-size: 13px;
  color: rgb(160, 160, 160);
  img {
    position: absolute;
    left: 8px;
    height: 28px;
  }
}
