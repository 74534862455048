.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.recharts-cartesian-axis-line {
  display: none;
}

.recharts-cartesian-axis-tick-line {
  display: none;
}

.recharts-text.recharts-cartesian-axis-tick-value tspan {
  font-family: "Montserrat", sans-serif !important;
  color: rgb(160, 160, 160) !important;
  fill: rgb(160, 160, 160) !important;
  margin: 10px !important;
  font-size: 13px !important;
}

#product-stats {
  .ant-table-thead .ant-table-cell {
    background: #fff9ef;
  }
  .ant-table-thead .ant-table-cell .row p {
    color: #3c3c3c;
    font-weight: bold;
  }
  .back-gey {
    padding: 15px 25px;
    background-color: #f6f6f6;
    border-radius: 8px;
  }
  .recharts-responsive-container {
    margin-left: -30px;
  }
}
