.affiliation {
  .left-column {
    width: 50%;
    padding: 10px;
    border-right: 1px solid rgb(224, 224, 224);
    height: 190px;
  }
  .right-column {
    width: 50%;
    padding: 10px;
    height: 190px;
    padding-left: 30px;
  }
  .link-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    .link-left {
      padding: 10px;
      border: 1px solid grey;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      max-width: 70%;
      height: 66px;
      overflow-wrap: break-word;
      overflow: auto;
    }
    .link-right {
      padding: 10px;
      border: 1px solid grey;
      border-left: none;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background: #fba95e;
      color: white;
      max-width: 30%;
      height: 66px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .affiliation-percentage {
    position: absolute;
    bottom: 0;
    left: 10px;
  }
}

@media screen and (max-width: 1150px) {
  .affiliation {
    .link-div {
      width: 100%;
      .link-left {
        width: calc(70% - 40px);
        overflow: auto;
      }
    }
    .card {
      width: 100%;
    }
    .left-column {
      height: auto;
      width: 100%;
    }
    .right-column {
      height: auto;
      width: 100%;
    }
    .affiliation-percentage {
      position: relative;
      left: auto;
      bottom: auto;
      margin-top: 15px;
    }
  }
}
