.boost {
  overflow-x: hidden;
  position: relative;
  max-width: 100vw;
  .back-1 {
    width: 100vw;
    background: linear-gradient(47deg, rgba(212, 249, 254, 1) 16%, rgba(255, 248, 223, 1) 73%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .boost-icon {
    width: 210px;
    margin-bottom: 7px;
  }
  .illustration-1 {
    width: 280px;
    margin-left: 20px;
  }
  .percent {
    font-size: 45px;
    margin-right: 5px;
    line-height: 30px;
    text-align: end;
    margin-right: 15px;
    margin-top: 6px;
  }

  .back-2 {
    width: 100vw;
    height: 250px;
    background: #1f1f1f;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .arrow-1 {
    position: absolute;
    top: 0;
    width: 250px;
    left: calc(50vw - 480px);
  }
  .arrow-2 {
    position: absolute;
    bottom: 0;
    width: 250px;
    right: calc(50vw - 500px);
  }

  .back-3 {
    background-image: url("../../images/illustrations/boostPresentation/back3.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .card-back-3 {
    background: #f9ffffe3;
    border-radius: 12px;
    padding: 50px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    background-image: url("../../images/landing/background3.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .img4 {
    width: 250px;
    margin-right: 30px;
  }
  .card-back-4 {
    border-radius: 8px;
    box-shadow: 1px 1px 2px rgb(187, 187, 187);
    background: #fefbf5;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 50px;
  }

  .agent-button {
    position: absolute;
    bottom: 15px;
    width: 200px;
    left: 60px;
    height: 40px;
  }

  .illustration6 {
    width: 50vw;
    margin-top: 40px;
    max-width: 800px;

    pointer-events: none;
  }

  .back-5 {
    background: #1f1f1f;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
    margin-top: 60px;
  }

  .illustration7 {
    width: 280px;
    margin-bottom: 20px;
  }
  .tiret {
    height: 1px;
    width: 30px;
    background: white;
    margin-bottom: 20px;
  }

  .illustration8 {
    width: 50vw;
    max-width: 800px;
    margin: 20px 0;
  }
  .roadmap1 {
    width: 50vw;
    max-width: 800px;
    justify-content: flex-start;
    margin-top: 50px;
  }
  .roadmap2 {
    width: 50vw;
    max-width: 800px;
    justify-content: center;
    position: relative;
    height: 100px;
    margin-bottom: 50px;
  }
  .roadmap-3 {
    position: absolute;
    left: 200px;
    bottom: 0;
  }
  .roadmap-4 {
    position: absolute;
    right: -20px;
  }

  .back-6 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
    height: 100px;
  }
  .arrow-1-2 {
    position: absolute;
    top: 0;
    width: 80px;
    left: calc(50vw - 230px);
  }
  .arrow-2-2 {
    position: absolute;
    bottom: 0;
    width: 80px;
    right: calc(50vw - 230px);
  }

  .illustration9 {
    width: 400px;
    margin-left: 100px;
    pointer-events: none;
  }

  .review-card {
    border-radius: 8px;
    box-shadow: 1px 1px 2px rgb(197, 197, 197);
    background: #f8f8f8;
    padding: 15px 30px;
    margin: 15px;
    width: 320px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .content {
    width: 50vw;
    max-width: 800px;
    justify-content: center;
  }

  .review-img {
    width: 280px;
    margin-bottom: 20px;
  }

  .faq{
    width: 600px;
  }

  @media screen and (max-width: 1150px) {
    .faq{
      width: 330px;
    }
    .arrow-1 {
      display: none;
    }
    .arrow-2 {
      display: none;
    }
    .illustration-1 {
      display: none;
    }

    .back-2 {
      padding: 20px 30px;
      height: auto;
    }
    .card-back-3 {
      padding: 20px 30px;
    }
    .img4 {
      display: none;
    }
    .card-back-4 {
      flex-direction: column;
    }
    .card-back-4 .column {
      margin: 10px;
    }
    .illustration6 {
      width: 95vw;
    }
    .back-4 {
      padding: 0 30px;
      padding-bottom: 50px;
    }
    .agent-button {
      position: relative;
      margin-top: 20px;
      margin: 0;
      margin-top: 30px;
      bottom: auto;
      left: calc(50vw - 110px);
    }
    .back-5 {
      flex-direction: column-reverse;
    }
    .back-5 .column {
      margin: 15px;
    }
    .back-5 .column.start {
      width: 90vw !important;
      margin-right: 0 !important;
      margin: 0;
    }
    .roadmap1 {
      flex-direction: column;
      width: 100vw !important;
      margin-top: 10px;
    }
    .roadmap2 {
      flex-direction: column;
      width: 100vw !important;
      margin-top: 50px;
    }
    .roadmap1 .column {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      width: 90vw !important;
      margin-right: 0 !important;
      margin-left: 0;
      margin: 20px;
    }
    .roadmap2 .column {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      width: 90vw !important;
      margin-right: 0 !important;
      margin-left: 0;
      margin: 20px;
    }
    .illustration8 {
      display: none;
    }
    .illustration9 {
      width: 90vw;
      margin-left: 0;
      max-width: 500px;
    }
    .row.start.review {
      flex-direction: column;
      margin-top: 30px;
    }
    .content {
      width: auto;
    }
    .content.faq {
      flex-direction: column;
      width: 95vw;
    }
    .back-4 .title-300.center {
      text-align: center;
    }
  }
}
