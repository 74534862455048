.coaching-page {
  background-image: url("../../images/resellerCoaching/background.svg");
  background-size: cover;
  width: 100vw;
  max-width: 100%;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h1 {
    margin-top: 40px;
    width: 80vw;
    text-align: start;
    margin-bottom: 50px;
  }
  .card {
    background: #f6f6f6;
    border-radius: 11px;
    padding: 30px 40px;
    position: relative;
    min-width: 350px;
    max-width: 450px;
    width: calc(30% - 40px);
    margin: 20px;
    box-shadow: 0 1px 2px rgb(214, 214, 214);
    z-index: 2;
  }
  .button-img {
    position: absolute;
    top: -30px;
    left: 30px;
    height: 60px;
  }
  .check {
    height: 20px;
    margin-right: 20px;
  }
  .illustration-right {
    position: absolute;
    width: 30%;
    left: calc(50%);
  }
  .illustration-left {
    position: absolute;
    width: 30%;
    right: calc(60%);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  .row-wrap {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 1150px) {
  .coaching-page {
    .row-wrap {
      justify-content: space-between;
      width: 100vw !important;
      padding: 0 3vw;
    }
    .card {
      width: 95vw;
      margin: 10px;
      margin-bottom: 40px;
      min-width: 330px;
    }
    h1 {
      margin-right: 0;
      margin-top: 40px;
    }
  }
}
