.stripeButton{
    background: rgb(87, 164, 253);
    padding: 11px 20px 11px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 4px rgb(209, 209, 209);
    cursor: pointer;
    margin-top: 0px;
}

.stripeButton-2{
    background: rgb(255, 255, 255);
    padding: 8px 16px 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: rgb(67, 136, 214);
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 4px rgb(196, 196, 196);
    cursor: pointer;
    margin-top: 0px;
}

.stripe-info-input{
    box-shadow: 1px 1px 3px rgb(184, 184, 184);
    border-radius: 4px;
    border: 1px rgb(80, 80, 80) solid;
    height: 34px;
    width: 50%;
    padding: 5px;
    margin-top: 10px;
}