.image-cover {
  width: calc(100% + 40px);
  margin-top: -20px;
  margin-left: -20px;
  object-fit: cover;
  max-height: 300px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.container-profile-picture {
  border-radius: 50%;
  top: 230px;
  left: 40px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  width: 160px;
  position: absolute;
}

.container-profile-picture .ant-upload-picture-card-wrapper {
  height: 160px;
  width: 160px;
  border-radius: 50%;
}

.container-profile-picture .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  height: 160px;
  width: 160px;
  border-radius: 50%;
}
