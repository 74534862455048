.jewel-menu {
  display: none !important;
  position: absolute;
  left: 0;
  top: 19px;
  width: 100vw;
  background: white;
  border-radius: 4px;
  padding: 50px 70px;
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  max-height: 70vh;
  overflow: scroll;
  cursor: initial;
  z-index: 10000;

  .title {
    font-size: 16px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .column {
    margin-right: 3vw;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.jewel-navbar {
  &:hover {
    .jewel-menu {
      display: flex !important;
      animation: menuDisplay 0.2s;
    }
  }
}

.mobile-categories .ant-menu-title-content p {
  font-size: 16px !important;
  text-transform: capitalize !important;
}

@keyframes menuDisplay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
