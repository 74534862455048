.percentage {
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #ffb56f;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 8px;
}

.active .percentage {
  background: white;
  color: #ffb56f;
  border: 1px solid #ffb56f;
}
.orange-border {
  border: #f4b67c 2px solid !important;
}

.pricing-card {
  border-radius: 10px;
  width: 295px;
  margin: 15px;
  padding: 25px 15px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px 3px rgb(223, 223, 223);
  background-color: white;

}
