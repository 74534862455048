.second-reseller-pricing-container {
  margin-top: 30px;
  padding-top: 20px;
  border-radius: 10px;
  width: 90vw;
  box-shadow: 2px 2px 8px rgb(197, 197, 197);

  .ant-table-tbody > tr[data-row-key="1"] > td {
    border: none;
  }

  .ant-table-tbody > tr[data-row-key="9"] > td {
    border: none;
  }

  .ant-table-tbody > tr[data-row-key="16"] > td {
    border: none;
  }

  .ant-table-tbody > tr[data-row-key="20"] > td {
    border: none;
  }

  .icon {
    width: 18px;
    height: auto;
  }

  .ant-table-wrapper {
    padding: 0 5vw;
    width: 100%;
  }

  .ant-table-header {
    margin-bottom: 30px;
  }

  .ant-table-thead > tr > th {
    background: white;
    border-bottom: 1px solid rgb(105, 105, 105);
  }

  .ant-table-cell-row-hover {
    background-color: white !important;
  }
}

@media screen and (max-width: 1150px) {
  
  .second-reseller-pricing-container {
    width: 100vw;

    .second-reseller-pricing-container .ant-table-wrapper {
      padding: 0 1vw;
    }
  }
}
