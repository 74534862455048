@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@400;500;600;700&display=swap");

$orange: #f48a10;
$purple: #6865e8;
$marine: #272a7d;
$purpose: #001b76;
$purpose-2: #3950a4;
$purpose-3: #5d6eb3;
$purpose-4: #e4e5f2;
$green: #91d698;
$red: rgb(233, 30, 30);
$grey: #f6f6f6;
$orange-gradient: linear-gradient(47deg, #fdca83 0%, #ffbb5b 100%);
$blue-gradient: linear-gradient(47deg, rgba(58, 206, 255, 1) 12%, rgba(0, 161, 255, 1) 53%);

/* ANTD */

.big-select .ant-progress-bg {
  height: 20px !important;
  background-color: $purpose-3;
}

.ant-progress-status-exception .ant-progress-bg {
  background-color: #ff4d4f !important;
}

.ant-input {
  border-radius: 6px;
  border: 1px solid #b9bfdf;
  width: 200px;
  height: 40px;
  font-size: 15px;
  color: #242424;
  background: #ffffff;
  &::placeholder {
    color: rgb(129, 129, 129);
  }
}

.ant-input-affix-wrapper.input-search {
  box-shadow: inset 1px 1px 3px rgb(184, 184, 184);
  border-radius: 10px;
  border: none;
  height: 40px;
  ::placeholder {
    color: rgb(149, 149, 149);
    font-weight: 300;
    font-size: 14px;
  }
}

.upgrade-plan-modal .ant-select-selector {
  height: 100% !important;
}

.border-red .ant-select-selector {
  border: 1px solid red !important;
}

html {
  --antd-wave-shadow-color: $orange;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: $orange;
  border-color: $orange !important;
}

::selection {
  background: $orange;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
  color: #4e4e4e;
}

.ant-modal-close-x {
  width: 50px;
  margin-right: 15px;
}

.ant-menu {
  background-color: transparent;
  border-right: none;
}

.a-hover:hover {
  color: white;
  background: linear-gradient(90deg, rgb(16, 66, 230) 28%, rgb(26, 124, 236) 91%);
}

.ant-picker {
  border-radius: 12px;
  border: 1px solid rgb(43, 43, 43);
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  width: 200px;
  height: 35px;
  font-size: 16px;
  color: rgb(36, 36, 36);
  &::placeholder {
    color: rgb(129, 129, 129);
  }
}
.editorClassName.rdw-editor-main {
  background: white;
  border-radius: 4px;
  height: 45vh;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0px;
  box-shadow: inset 0px 0px 2px rgb(148, 148, 148);
}

.ant-input-suffix .button {
  margin-right: -10px;
}

.ant-slider-rail {
  height: 8px;
}

.ant-slider-track {
  height: 8px;
  background-color: $marine;
}

.ant-slider-handle.ant-tooltip-open {
  height: 18px;
  width: 18px;
  border: $marine 3px solid;
}

.ant-tooltip-inner {
  background-color: $marine;
  font-weight: bold;
  min-width: 70px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 10px 12px;
}

.green-puce {
  min-height: 8px;
  min-width: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: $green;
}

.red-puce {
  min-height: 8px;
  min-width: 8px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: $red;
}

.ant-tooltip {
  z-index: 10000;
}

.ant-tooltip-arrow-content::before {
  background: $marine !important;
}

.location-search-input {
  border-radius: 12px;
  border: 1px solid rgb(43, 43, 43);
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  width: 200px;
  height: 35px;
  font-size: 16px;
  color: rgb(36, 36, 36);
  background-color: white;
  padding: 5px;
  &::placeholder {
    color: rgb(129, 129, 129);
  }
}

.ant-input-affix-wrapper {
  border-radius: 6px;
  border: 1px solid #b9bfdf;
  width: 200px;
  height: 40px;
  font-size: 15px;
  color: #242424;
  background: #ffffff;
  &::placeholder {
    color: rgb(129, 129, 129);
  }
}

.ant-input-affix-wrapper .ant-input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border-radius: 0px;
}

.input-header {
  position: absolute;
  right: 120px;
}

.row.space-between {
  justify-content: space-between;
  width: 100%;
}

.ant-form.ant-form-horizontal {
  width: 100%;
}

.ant-col.ant-col-16.ant-form-item-control {
  min-width: 100%;
}

.ant-col.ant-col-16.ant-col-offset-8.ant-form-item-control {
  margin: 0;
}

.ant-col.ant-col-8.ant-form-item-label {
  min-width: 100%;
  display: flex;
  justify-content: flex-start;
}

.ant-row.ant-form-item.inputChooseLoginAntd {
  min-height: 80px;
}

.ant-row.ant-form-item.item-signup {
  min-height: 80px;
}

.ant-row.ant-form-item.passwordRepeatText {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-upload {
  overflow: hidden !important;
}

.ant-upload-list-item.ant-upload-list-item-error.ant-upload-list-item-list-type-picture-card {
  border: 1px solid rgb(141, 141, 141);
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  height: 150px;
  width: 150px;
  border-radius: 10px;
  font-size: 50px;
}

.ant-upload-picture-card-wrapper {
  width: auto;
}

.ant-upload-list.ant-upload-list-picture-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.ant-menu-submenu.ant-menu-submenu-inline {
  min-height: 50px;
}

textarea.ant-input {
  width: 350px;
  height: 150px;
}

textarea.ant-input.modal-textarea {
  width: 90%;
  height: 90px;
}

.ant-slider-rail {
  background-color: rgb(173, 173, 173);
}

.ant-input-number {
  border-radius: 6px;
  color: #242424;
  border: 1px solid #b9bfdf;
  background: #ffffff;
}

.ant-input-number-handler-wrap {
  opacity: 100%;
  border-radius: 6px;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-order .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.modal-order .ant-btn {
  border-radius: 5px;
}

.page.sourcing textarea.ant-input {
  width: 100%;
  &::placeholder {
    font-size: 13px;
    font-weight: 400;
  }
}

.ant-modal.modal-order {
  width: 70% !important;
  min-width: 700px;
  max-width: 800px;
}

.modal-account .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.modal-account .ant-btn {
  border-radius: 5px;
}

.ant-modal.modal-account {
  width: 70% !important;
  min-width: 300px;
  max-width: 400px;
  padding: 0px !important;
}

.ant-modal.modal-small {
  width: 70% !important;
  min-width: 300px;
  max-width: 400px;
  padding: 0px !important;
}

.marine .ant-radio-inner::after {
  background: $marine !important;
}

.marine .ant-radio-inner {
  border-color: $marine !important;
}

.ant-radio-inner::after {
  background: $purpose-2;
}

.ant-radio-inner {
  border-color: $purpose-2 !important;
}

.ant-modal.modal-disclamer {
  width: 70% !important;
  min-width: 350px;
  max-width: 420px;
  padding: 0px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ant-modal.large {
  width: 70% !important;
  max-width: 1100px;
}

.ant-modal-body {
  padding: 40px;
}

.text-red {
  color: rgb(253, 42, 42);
}

.user-icon-popup {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  cursor: pointer;
}

.modal-override .ant-modal-footer {
  display: flex;
  justify-content: center;
}

.modal-override .ant-btn {
  border-radius: 5px;
}

.react-joyride__overlay {
  z-index: 10002 !important;
}

.__floater.__floater__open {
  z-index: 10003 !important;
}

.row-group-images {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.ant-input.input.marketplace {
  border: 1px solid #70c4f3;
  box-shadow: none;
  height: 50px;
  border-radius: 8px;
  width: calc(100% - 100px);

  &::placeholder {
    color: rgb(201, 201, 201);
  }
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  background: rgb(250, 248, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 10;
}

.column-image {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 15px;
  padding: 10px;
  border: 1px rgb(212, 212, 212) solid;
  border-radius: 5px;
  width: 250px;
  height: 290px;
  position: relative;
  overflow: hidden;
}

p {
  color: rgb(36, 36, 36);
  margin-bottom: 0px;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-family: "Cabin", sans-serif;
  color: rgb(36, 36, 36);
}

h2 {
  font-family: "Cabin", sans-serif;
  color: rgb(36, 36, 36);
}

li {
  font-family: "Montserrat", sans-serif;
}

h3 {
  font-family: "Cabin", sans-serif;
  color: rgb(36, 36, 36);
}

a {
  font-family: "Cabin", sans-serif;
  color: rgb(36, 36, 36);
  text-decoration: none;
}

.text-gradient {
  background: $blue-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  cursor: pointer;
}

.link {
  color: #70c4f3;
  font-size: 15px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-decoration: underline;
}

.page {
  min-height: 100vh;
  width: 100%;
  background-color: rgb(245, 245, 245);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}

.italic {
  font-style: italic;
}

.card-box {
  box-shadow: 1px 1px 4px rgb(180, 180, 180);
  border-radius: 4px;
}

.text-ref {
  color: rgb(112, 112, 112);
  font-size: 13px;
  text-decoration: underline;
  font-family: "Roboto", sans-serif;
}
.title {
  font-size: 20px;
  text-align: center;
  color: rgb(65, 65, 65);
}
.title-500 {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: rgb(65, 65, 65);
}

.big-title {
  font-size: 40px;
  text-align: center;
  color: rgb(65, 65, 65);
}

.big-title-2 {
  font-size: 60px;
  text-align: center;
  color: rgb(65, 65, 65);
}

.scroll-x {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 5px !important;
  }
}

.ant-carousel {
  width: 100%;
}

.slick-dots.slick-dots-bottom {
  background: #00000099;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.ant-carousel .slick-dots li button {
  height: 7px;
  width: 35px;
}

.ant-carousel .slick-dots li {
  margin: 5px;
  width: 35px;
  height: 7px;
}

.ant-carousel .slick-dots li.slick-active {
  width: 35px;
}

.text-blog-card {
  position: absolute;
  left: 15px;
  top: 60px;
  color: white;
  font-weight: 700;
  background-color: rgba(22, 22, 22, 0.747);
  font-family: "Montserrat", sans-serif !important;
  padding: 8px;
  font-size: 18px;
  line-height: 20px;
}

.title-300 {
  font-size: 20px;
  text-align: start;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.title-600 {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.title-200 {
  font-size: 20px;
  text-align: center;
  font-weight: 200;
  font-family: "Roboto", sans-serif;
}

.text-400 {
  font-size: 16px;
  text-align: start;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.text-300 {
  font-size: 16px;
  text-align: start;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.text-200 {
  font-size: 16px;
  text-align: center;
  font-weight: 200;
  font-family: "Roboto", sans-serif;
}

.title-orange {
  font-size: 15px;
  color: rgb(255, 186, 58);
  margin-right: 20px;
}

.title-red {
  font-size: 15px;
  color: rgb(255, 58, 58);
  margin-right: 20px;
}

.title-red-big {
  font-size: 22px;
  color: rgb(252, 92, 92);
  margin-right: 20px;
}

.title-2 {
  font-size: 15px;
}

.page-start {
  min-height: 100vh;
  width: 100%;
  background-color: rgb(245, 245, 245);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  overflow-x: hidden;
}

.back-loading {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.363);
  position: fixed;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
}

.gif-load-01supply {
  height: 200px;
  width: auto;
}

.row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
}

.column {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.column.center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100vw;
  padding: 30px;
  padding-right: 15vw;
  padding-left: 15vw;
}

.blur {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(105, 105, 105, 0.116);
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey-back {
  height: 100%;
  width: 100%;
  background: rgba(77, 77, 77, 0.11) !important;
  top: 0;
  left: 0;
  border-radius: 8px;
  position: absolute;
}

.grey-back-2 {
  background: rgb(200, 200, 200) !important;
  border-radius: 8px;
  position: relative;
  color: rgb(87, 87, 87) !important;
}

.grey-back-3 {
  background: rgb(240, 240, 240) !important;
  border-radius: 8px;
  padding: 15px;
  position: relative;
  color: rgb(87, 87, 87) !important;
}

.white {
  color: white !important;
}

.row-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
  flex-wrap: wrap;
}

.row-width {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.column-width {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 65px;
}

.column-scrolling {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
  width: calc(100% - 225px);
  -webkit-overflow-scrolling: touch;
  padding-top: 150px;
  position: relative;
  padding-left: 30px;
  padding-bottom: 90px;
}

.column {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.column-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.text-bold {
  font-weight: bold;
  color: rgb(63, 63, 63);
}

.title-bold {
  font-weight: bold;
  color: rgb(63, 63, 63);
  font-size: 22px;
}

.underline {
  text-decoration: underline;
}

.text-underline-hover {
  &:hover {
    border-bottom: 1px solid black;
  }
  cursor: pointer;
}

input {
  background-color: transparent;
  border: none;
  border-bottom: 1px rgb(66, 66, 66) solid;
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.rc-virtual-list-holder {
  overflow: hidden;
}

.margin-top-landing {
  margin-top: 80px;
}

.grey-card {
  border-radius: 8px;
  background-color: #f6f6f6;
  padding: 20px;
}

.thematic-option {
  margin: 10px;
  height: 150px;
  width: 150px;
  cursor: pointer;
}

.thematic-option.active {
  border: 6px solid $orange;
  border-radius: 12px;
}

.button-grey {
  border-radius: 6px;
  color: rgb(70, 70, 70);
  box-shadow: 0.5px 0.5px 3px rgb(224, 224, 224);
  font-weight: 700;
  cursor: pointer;
  border: none;
  background-color: #cbcbcb;
  padding: 10px 25px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  margin: 10px 0;
}

.button-2 {
  border-radius: 8px;
  box-shadow: 1px 1px 2px rgb(221, 221, 221);
  border: $orange solid 2px;
  color: $orange;
  padding: 7px 20px;
  background: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  width: fit-content;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif !important;
  &:hover {
    box-shadow: 2px 2px 8px rgb(224, 224, 224), 2px 2px 8px rgb(224, 224, 224);
    background: $orange;
    color: white;
  }
}

.button.big {
  padding: 6px 16px 6px 16px;
  font-size: 20px;
  &:hover {
    padding: 6px 16px 6px 16px;
  }
}

.button.big.grey {
  &:hover {
    padding: 6px 16px 6px 16px;
  }
}

.button.big.purple-back {
  &:hover {
    padding: 6px 16px 6px 16px;
    color: white;
  }
}

.message-card-receiver {
  background-color: rgb(177, 177, 177);
  width: 60%;
  margin-right: 40%;
  border-radius: 12px;
  border: 1px solid rgb(214, 214, 214);
}

.orange-back-gradient {
  background: $orange-gradient;
}

.message-card {
  border-radius: 9px;
  padding: 15px 20px 20px 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  position: relative;
  margin-top: 18px;
}

.orange-back-3 {
  background: #ffe0c3;
  background-color: #ffe0c3;
}

.message-card.sender {
  width: 60%;
  margin-left: 40%;
  background: $orange-gradient;
  color: white;
}

.message-card.receiver {
  width: 60%;
  margin-right: 40%;
  background: rgb(212, 212, 212);
  color: rgb(48, 48, 48);
}

.content-describe-input {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.content-describe-input .ant-form-item-control-input {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.content-describe-input .ant-form-item-control-input .ant-form-item-control-input-content {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.ant-input.describe-input {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100% !important;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.ant-select-selector {
  border-radius: 8px !important;
  border: 1px solid rgb(116, 116, 116) !important;
}

.describe-input {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.back-join-us {
  background-image: url("../images/back_join_us.png");
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

.create-product .ant-select-selector {
  width: 200px;
  height: auto;
  padding: 10px;
}

.create-product .ant-select-selection-overflow-item {
  margin: 3px;
}

.table-container {
  width: 100%;
  border: 1px solid rgb(100, 100, 100);
  border-radius: 4px;
  height: 50px;
  overflow-y: scroll;
  background-color: white;
}

.row-table {
  width: 100%;
  border-bottom: 1px solid rgb(177, 177, 177);
  height: 25px;
  padding: 5px;
}

.button {
  text-align: center;
  border: none;
  padding: 12px 20px 12px 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  color: white;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  letter-spacing: 0.3px;

  &:hover {
    .button-icon {
      display: none;
      width: 0 !important;
      margin: 0 !important;
    }
  }
}

.row.center {
  justify-content: center;
  align-items: center;
}

.orange-back.no-hover {
  &:hover {
    background: #ffb56f !important;
    color: white;
    padding: 8px 11px 8px 11px;
    border-radius: 9px;
    width: auto;
  }
}

.view-import-list {
  position: absolute;
  top: 15px;
  box-shadow: 0px 0px 3px rgb(202, 202, 202);
  background: white;
  cursor: pointer;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  animation: top-bottom 0.5s;
}

@keyframes top-bottom {
  from {
    top: 0px;
  }
  to {
    top: 15px;
  }
}

.back-grey {
  padding: 30px;
  background: $grey;
}

.title-montserrat.big {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif !important;
  line-height: 45px;
  text-align: start;
  color: rgb(61, 61, 61);
  margin-bottom: 40px;
  text-align: center;
}

.bottom-save {
  z-index: 100;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 130px;
  width: calc(100% - 225px);
  background: white;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
  box-shadow: 0px -1px 3px #e7e7e7;
}

.montserrat-700 {
  font-weight: 700;
  font-family: "Montserrat", sans-serif !important;
  color: #4e4e4e;
}

.montserrat-800 {
  font-weight: 800;
  font-family: "Montserrat", sans-serif !important;
  color: #4e4e4e;
}

.pricing-label {
  background: rgb(255, 127, 127);
  color: white;
  font-size: 13px;
  font-weight: bold;
  border-radius: 6px;
  padding: 4px 12px;
  transform: rotate(-2deg);
  margin-bottom: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-orange {
  text-align: center;
  border: none;
  padding: 8px 11px 8px 11px;
  border-radius: 9px;
  border: 2px solid $orange;
  background: transparent;
  color: $orange;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    color: #fdb550;
  }
}

.button.grey {
  background: rgb(218, 218, 218);
  color: rgb(83, 83, 83);
}

.label-anable {
  background-color: rgba(155, 218, 155, 0.788);
  border: rgba(40, 156, 40, 0.836) 2px solid;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  padding: 3px 6px;
  font-size: 14px;
}

.paypal-button {
  background-color: #015ea6;
  border: none;
  color: rgb(255, 255, 255);
  padding: 11px 20px 11px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgb(209, 209, 209);
  cursor: pointer;
  margin-top: 0px;
}

.initial-cursor {
  cursor: initial;
}

.orange-back {
  background: $orange !important;
}

.orange-back-outlined {
  border: $orange 2px solid !important;
  color: $orange;
  background: white;
}

.orange-back-2 {
  background: #ffc188 !important;
}

.blue-back {
  background: #9cd7ff !important;
}

.green-back {
  background: $green !important;
}

.marine {
  color: #272a7d !important;
}

.back-marine {
  background-color: #272a7d !important;
}

.iban-button {
  background-color: #3090eb;
  border: none;
  color: rgb(255, 255, 255);
  padding: 11px 20px 11px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgb(209, 209, 209);
  cursor: pointer;
  margin-top: 0px;
}

.button-grey {
  box-shadow: 1px 1px 2px rgb(225, 225, 225);
  border-radius: 6px;
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #c8c9cc;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: hidden;
}

.ant-menu-item.ant-menu-item-selected {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  font-weight: bold;
  color: rgb(31, 31, 31) !important;
  height: 50px;
  border-left: 4px solid #ffb56f;
  background-color: rgba(255, 255, 255, 0) !important;
}

.ant-menu-submenu-title {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  color: rgb(37, 37, 37);
  height: 50px;
}

.ant-menu-item.ant-menu-item-only-child {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 60px);
  margin-left: -30px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  color: rgb(37, 37, 37);
  height: 50px;
}

.grey {
  color: rgb(102, 102, 102) !important;
}

.ant-menu-item-selected a {
  color: white;
}

.title-green-join-us {
  font-family: "Roboto", sans-serif;
  color: rgb(63, 216, 114);
  font-size: 25px;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 30px;
}

.blocked-product {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.719);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 6px;
}

.button-blue {
  border: none;
  padding: 4px 11px 4px 11px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(27, 75, 177);
  background: #0e09f0;
  color: white;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.hover-underline:hover {
  border-bottom: 1px solid white;
  color: white;
}

.button-gradient {
  border-radius: 9px;
  border: 2px solid rgba(46, 109, 236, 1);
  background: linear-gradient(90deg, rgba(46, 109, 236, 1) 51%, rgb(32, 173, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  color: rgba(46, 109, 236, 1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 12px 5px 12px;
  font-weight: bold;
}

.product-card-sales-evolution {
  color: #f3a648;
  font-size: 15px;
  font-weight: 600;
  max-width: 90%;
}

.divider {
  height: 1px;
  background-color: rgb(219, 219, 219);
  width: 100%;
}

.button-shadow {
  background: white;
  box-shadow: 1px 1px 5px #d4d4d4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 8px 20px;
  position: relative;
  cursor: pointer;
}

.product-card-percent-evolution {
  width: 90%;
  border: 2px solid rgb(255, 199, 134);
  color: rgb(255, 199, 134);
  border-radius: 8px;
  height: 25px;
  font-size: 16px;
}

.count-circle {
  background: linear-gradient(47deg, rgba(255, 216, 162, 1) 0%, rgba(255, 168, 0, 1) 100%);
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -2px;
  font-size: 10px;
  font-weight: bold;
}

.count-circle-red {
  background: linear-gradient(47deg, rgba(255, 109, 109, 1) 7%, rgba(255, 56, 56, 1) 36%);
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 3px;
  position: absolute;
  top: 9px;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  right: 15px;
  min-width: 25px;
  max-width: 30px;
  padding: 3px 6px;
}

.count-circle-red.active {
  right: 50px;
}

.count-circle-orange {
  background: linear-gradient(47deg, rgb(255, 202, 127) 0%, rgba(255, 168, 0, 1) 100%);
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 3px;
  position: absolute;
  top: 9px;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  right: 15px;
  min-width: 25px;
  max-width: 30px;
  padding: 3px 6px;
}

.count-circle-orange.active {
  right: 50px;
}

.count-circle-grey {
  background: rgb(190, 190, 190);
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 3px;
  position: absolute;
  top: 9px;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  right: 15px;
  min-width: 25px;
  max-width: 30px;
  padding: 3px 6px;
}

.count-circle-grey.active {
  right: 50px;
}

.ant-drawer-content-wrapper {
  width: auto !important;
}

.button-black {
  border: none;
  padding: 8px 11px 8px 11px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: rgb(73, 73, 73);
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-disable {
  border: none;
  padding: 8px 11px 8px 11px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: linear-gradient(90deg, rgba(22, 76, 255, 0.349) 28%, rgba(32, 136, 255, 0.363) 91%);
  filter: grayscale(30%);
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-disable-grey {
  border: none;
  padding: 8px 11px 8px 11px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: rgba(150, 150, 150, 0.418);
  filter: grayscale(30%);
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.infinite-scroll-component__outerdiv {
  width: 70%;
}

.width-100 {
  width: 100%;
}

.border-grey {
  border-radius: 8px;
  border: solid 1px rgb(230, 230, 230);
  box-shadow: none !important;
}

.button-disable-orange {
  border: none;
  padding: 8px 11px 8px 11px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: rgba(255, 163, 87, 0.418);
  filter: grayscale(30%);
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-white-red {
  border: none;
  padding: 5px 35px 5px 35px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(219, 219, 219);
  background: white;
  color: rgb(255, 98, 98);
  border: 1px solid #c08f8f;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.button-white-gradient {
  border: none;
  padding: 5px 35px 5px 35px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(219, 219, 219);
  background: white;
  color: #3a7af1;
  border: 1px solid #8fa0c0;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.button-white {
  border: none;
  padding: 8px 11px 8px 11px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: white;
  border: 2px solid $orange;
  color: $orange;
  font-weight: bold;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
}

.button-red {
  border: none;
  padding: 5px 8px 5px 8px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(240, 240, 240);
  color: rgb(238, 91, 91);
  border: 2px solid rgb(238, 91, 91);
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.658);
  cursor: pointer;
}

.button-red-400 {
  border: none;
  padding: 5px 8px 5px 8px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(240, 240, 240);
  color: rgb(238, 91, 91);
  border: 1px solid rgb(238, 91, 91);
  font-weight: 400;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.658);
  cursor: pointer;
}

.button-back-red {
  border: none;
  padding: 5px 8px 5px 8px;
  border-radius: 9px;
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #e83535bd;
}

.little-button-red {
  border: none;
  padding: 3px 5px 3px 5px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  color: #e83535;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px #e83535 solid;
  cursor: pointer;
}

.icon-menu.marketplace {
  display: none;
}

.link-product-card {
  &:hover {
    color: #303030 !important;
  }
}

.marketplace-menu {
  padding: 7px 15px;
  background-color: #ffb164c2;
  color: white;
  width: 190px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  box-shadow: 1px 1px 0px rgb(248, 248, 248);
  &:hover {
    padding: 5px 13px;
    font-weight: 450;
    border: 2px solid #ffb164c2;
    background-color: white;
    font-weight: bold;
    color: #ffb164c2;
    .icon-menu.marketplace {
      display: block;
    }
    .icon-menu.marketplace-white {
      display: none;
    }
  }
}

.button-absolute {
  border: none;
  padding: 15px 45px 15px 45px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  color: white;
  font-weight: bold;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 80px;
  z-index: 1000;

  &:hover {
    padding: 13px 43px 13px 43px;
    background-color: $orange !important;
    color: white;
  }
}

.text-end {
  text-align: end !important;
  width: 100%;
}

.width-60-vw {
  width: 60vw !important;
}

.button-absolute-top {
  border: none;
  padding: 12px 18px 12px 18px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  color: white;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  top: 130px;
  left: 50px;
}

.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.settings-payments-button {
  width: 290px;
  height: 60px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 6px;
  border: 2px solid rgb(31, 134, 252);
  color: rgb(31, 134, 252);
  box-shadow: 1px 1px 2px rgb(212, 212, 212);
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  align-items: center;
  background-color: white;
}

.button-absolute-top-right {
  border: none;
  padding: 5px 8px 5px 8px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  color: #e83535;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px #e83535 solid;
  background-color: #e8353500;
  cursor: pointer;
  position: absolute;
  top: 140px;
  left: 500px;
}

.add-absolute {
  position: fixed;
  bottom: 50px;
  right: 40px;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 2px rgb(122, 122, 122);
  background-color: $orange;
  cursor: pointer;
  font-size: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
  &:hover {
    color: white;
  }
}

.info-banner {
  background: $purpose-4;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(218, 218, 218);
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: rgb(45, 45, 45);
  padding: 12px 20px;
}

.button-in-input {
  border: none;
  padding: 10px 25px 10px 25px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgb(226, 226, 226);
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  color: white;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  right: 0;
  height: 100%;
}

.button-in-input.orange {
  background-color: $orange;
  background: $orange;
  color: white;
}

.button-in-input-big {
  border: none;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: absolute;
  right: -50px;
  font-weight: bold;
}

.button-image {
  border: none;
  padding: 6px 9px 6px 9px;
  border-radius: 3px;
  box-shadow: 0px 0px 1.5px rgb(0, 0, 0);
  background: white;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: rgb(41, 41, 41);
  position: absolute;
  bottom: 15px;
}

.title-board {
  padding: 20px;
  padding-right: 50px;
  font-size: 22px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 25px;
  color: rgb(37, 37, 37);
  background-color: rgb(250, 250, 250);
  margin-bottom: 22px;
  border: 1px rgb(212, 212, 212) solid;
  position: absolute;
  top: 0px;
}

.across-landing {
  height: 120px;
  width: auto;
}

.container-menu {
  width: 225px;
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  box-shadow: 0px 3px 2px rgb(167, 167, 167);
}

.board-left-menu {
  width: 100%;
  height: calc(100vh - 160px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 15px;
  padding-right: 10px;
}

.menu-orange-back {
  background: #ffeed356;
  border-radius: 0 8px 8px 0;
  position: absolute;
  left: -15px;
  height: 35px;
  width: 210px;
  top: -7px;
  z-index: 1;
}

.board-left-menu.contact-list {
  width: auto;
  height: 100vh;
  overflow: hidden;
  padding-left: 0;
  background-color: rgba(255, 252, 248, 0.788);
  padding-right: 0;
}

.step-be {
  width: 800px;
}

.board-right {
  display: flex;
  margin-top: 20px;
  width: 400px;
  margin-left: 150px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.image-status {
  width: 40vw;
  height: auto;
}

.button-variant {
  text-align: center;
  border: 1px solid rgb(51, 51, 51);
  padding: 8px 11px 8px 11px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  color: rgb(51, 51, 51);
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  background-color: white;
}

.tab-product-details {
  background-color: white;
  border-radius: 2px;
  padding: 20px;
  padding-top: 0px;
  width: 95%;
}

.button-variant.on {
  color: rgb(17, 17, 17);
  border: 2px solid rgb(17, 17, 17);
}

.button-variant.disable {
  color: rgb(177, 177, 177);
  border: 1px solid rgb(177, 177, 177);
}

.img-variant {
  width: 33px;
  height: auto;
  border-radius: 8px;
  padding: 5px;
  box-shadow: 1px 1px 3px rgb(165, 165, 165);
  margin-right: 15px;
  background-color: white;
  cursor: pointer;
}

.img-select-variant {
  margin: 20px;
  width: 150px;
  height: auto;
  overflow: hidden;
  border-radius: 11px;
  box-shadow: 0px 0px 4px rgb(197, 197, 197);
  cursor: pointer;
}

.title-illustration-error {
  font-size: 30px !important;
  max-width: 70vw;
  color: #4072dd !important;
  width: 100%;
  font-weight: 300;
  margin-bottom: 50px;
}

.text-head-offer {
  color: red;
  position: absolute;
  top: 20px;
  width: 90%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-right-start {
  display: flex;
  margin-top: 50px;
  width: 400px;
  margin-left: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.landing-illustration {
  width: 300px;
  height: auto;
}

.title-landing {
  font-size: 30px;
  color: rgb(255, 146, 56);
  margin-bottom: 0px;
  margin-top: 30px;
}

.title-landing-2 {
  font-size: 30px;
  color: rgb(80, 141, 255);
  margin-bottom: 0px;
  margin-top: 0px;
}

.text-landing {
  font-size: 22px;
  color: rgb(32, 32, 32);
  font-weight: 300;
  margin-bottom: 30px;
}

.banner-extension {
  width: 80%;
  max-width: 1000px;
  height: auto;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.text-menu {
  font-size: 14px;
  margin-bottom: 3px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  color: #424242;
  padding: 10px;
  padding-left: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.double-button {
  font-family: "Montserrat", sans-serif;
  color: #ffb56f;
  border: 2px solid #fdca83;
  height: 55px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.double-button.left {
  border-radius: 8px 0px 0px 8px;
  border-right: 1px solid #fdca83;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: #ffb56f;
  }
}

.double-button.left.active {
  background: #fdca83;
  color: white;
  cursor: initial;
  &:hover {
    color: white;
  }
}

.double-button.right {
  border-radius: 0px 8px 8px 0px;
  border-left: 1px solid #fdca83;
  cursor: pointer;
  text-align: center;
  &:hover {
    color: #ffb56f;
  }
}

.double-button.right.active {
  background-color: #fdca83;
  color: white;
  cursor: initial;
  &:hover {
    color: white;
  }
}

.ant-menu-submenu-title .ant-menu-title-content {
  font-size: 17px;
}

.ant-menu-submenu.ant-menu-submenu-inline {
  margin-bottom: -7px;
}

.text-menu-active {
  font-size: 14px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  height: 30px;
  border-left: 4px solid #ffb56f;
  background: #fbaa5e0c;
  width: calc(100% + 50px);
  color: rgb(31, 31, 31) !important;
  margin-left: -15px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 450;
}

.text-menu-setting {
  font-size: 17px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  height: 50px;
  width: calc(100% + 65px);
  color: rgb(31, 31, 31) !important;
  margin-left: -30px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 450;
}

.text-menu-setting-active {
  font-size: 17px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  height: 50px;
  border-left: 4px solid #ffb56f;
  background: #fbaa5e0c;
  width: calc(100% + 65px);
  color: rgb(31, 31, 31) !important;
  margin-left: -30px;
  padding: 10px;
  padding-left: 25px;
  padding-right: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 450;
}

.video-iframe {
  width: 500px;
  height: 280px;
  border-radius: 8px;
  box-shadow: 2px 2px 4px rgb(199, 199, 199);
}

.option-bottom {
  background: linear-gradient(90deg, rgba(46, 109, 236, 1) 51%, rgba(32, 203, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  position: fixed;
  bottom: 20px;
  cursor: pointer;
}

.option-top {
  background: linear-gradient(90deg, rgba(46, 109, 236, 1) 51%, rgba(32, 203, 255, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 22px;
  position: absolute;
  top: 30px;
  cursor: pointer;
}

.icon-menu {
  width: 14px;
  height: auto;
  margin-right: 10px;
  color: #585858 !important;
}

.row-inventory {
  width: 95%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(202, 202, 202);
}

.reseller .row-inventory {
  width: 60%;
  height: 190px;
}

.image-product-inventory {
  border-radius: 10px;
  object-fit: contain;
  height: auto;
  max-height: 250px;
}

.title-blue {
  font-size: 22px;
  color: rgb(54, 148, 255);
}

.card-accompte-not-valid {
  border-radius: 2px;
  background-color: none;
  border: 2px rgb(255, 54, 54) solid;
  box-shadow: 0px 0px 1px rgb(172, 172, 172);
  width: 80%;
  height: auto;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}

.checkbox-on-product {
  position: absolute;
  top: 8px;
  left: 8px;
}

.checkbox-on-product .ant-checkbox-inner {
  height: 25px;
  width: 25px;
}

.checkbox-on-product .ant-checkbox-checked .ant-checkbox-inner::after {
  height: 15px;
  width: 10px;
  top: 10px;
}

.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-product-card-label {
  color: white;
  position: absolute;
  left: 40px;
  font-size: 13px;
  width: max-content;
}

.label-product-card {
  width: 240px;
  margin-right: calc(100% - 240px);
}

#import-product-button {
  font-size: 16px;
}

#import-product-button mobile {
  display: none;
}

.only-mobile {
  display: none;
}

#cancel-product-card {
  width: 40%;
  margin-right: 5%;
  height: 8px;
  box-shadow: 1px 1px 3px rgb(199, 199, 199);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  border: none;
  border-radius: 13px;
  height: 50px;
}

.text-menu-active-2 {
  border-bottom: 2px solid rgb(255, 195, 105);
}

#import-product-button-little {
  box-shadow: 1px 1px 3px rgb(199, 199, 199);
  font-size: 16px;
  width: 40%;
  margin-left: 5%;
  height: 50px;
  border-radius: 13px;
}

.container-image-card {
  width: 100%;
  height: 170px;
  overflow: hidden;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.new-label {
  background: linear-gradient(47deg, rgba(255, 188, 109, 1) 41%, rgba(255, 188, 56, 1) 85%);
  box-shadow: 1px 1px 3px #ffe0a9;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 9px;
  margin-bottom: 3px;
  margin-left: 10px;
}

.container-card-product {
  width: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 1px 3px rgb(231, 231, 231);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  &::after {
    content: "";
    border-radius: 8px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 6px rgba(173, 173, 173, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.125, 0.64, 0.4, 1);
    transition: all 0.6s cubic-bezier(0.125, 0.64, 0.4, 1);
  }

  &:hover {
    -webkit-transform: scale(1.03, 1.03);
    transform: scale(1.03, 1.03);
  }

  &::after {
    opacity: 1;
  }
}

.container-card-product.link-product-card.disabled {
  &::after {
    -webkit-transition: initial !important;
    transition: initial !important;
  }

  &:hover {
    -webkit-transform: initial !important;
    transform: initial !important;
  }

  &::after {
  }
  cursor: initial;
}

.align-end {
  align-items: flex-end;
}

.back-purple {
  background-color: #6865e8;
  background: #6865e8;
}

.over-effect {
  &::after {
    content: "";
    border-radius: 8px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 3px 6px rgba(173, 173, 173, 0.3);
    opacity: 0;
    -webkit-transition: all 0.6s cubic-bezier(0.125, 0.64, 0.4, 1);
    transition: all 0.6s cubic-bezier(0.125, 0.64, 0.4, 1);
  }
  &:hover {
    -webkit-transform: scale(1.01, 1.01);
    transform: scale(1.01, 1.01);
  }
  -webkit-transition: all 0.6s cubic-bezier(0.13, 0.74, 0.4, 1);
  transition: all 0.6s cubic-bezier(0.13, 0.74, 0.4, 1);
}

.bottom-card-product {
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 15px;
}

.price-product-card {
  font-size: 16px;
  position: absolute !important;
  bottom: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgba(43, 206, 43, 0.466);
  width: 170px;
  min-width: 170px;
  text-align: center;
  color: green;
  min-height: 25px !important;
  padding-left: 10px;
  padding-right: 10px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.column.right-window {
  width: calc(100vw - 225px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.container-card-supplier {
  width: 200px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  background-color: white;
  border-radius: 12px;
  box-shadow: 1px 1px 2px rgb(206, 206, 206);
}

.text-absolute-center {
  color: white;
  font-weight: bold;
  position: absolute !important;
  z-index: 10;
  font-size: 25px;
  max-width: 90%;
}

.container-img-inventory-product {
  width: 250px;
  min-width: 250px;
  height: 150px;
  margin-bottom: 20px;
  margin-right: 30px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.container-img-inventory-product.import-csv {
  height: 100px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 6px;
}

.image-product {
  object-fit: cover;
  min-height: 170px;
  width: 100%;
  vertical-align: top;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.banner-bottom {
  position: fixed;
  bottom: 0px;
  width: calc(100vw - 225px);
  height: 50px;
  right: 0;
  background: white;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px 20px;
}

.banner-bottom p {
  line-height: 18px;
}

.button-banner {
  height: 30px;
  width: 250px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  color: rgb(55, 82, 233);
  font-size: 16px;
  margin-left: 30px;
  box-shadow: 1px 1px 3px rgba(54, 124, 189, 0.952);
  border: 1px solid rgb(55, 82, 233);
  cursor: pointer;
}

.button-banner-2 {
  height: 30px;
  border-radius: 8px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  color: rgb(55, 82, 233);
  font-size: 16px;
  margin-left: 30px;
  box-shadow: 1px 1px 3px rgba(54, 124, 189, 0.952);
  border: 1px solid rgb(55, 82, 233);
  cursor: pointer;
  min-width: 165px;
}

.border-red {
  &:placeholder-shown {
    border: 1px solid red;
  }
}

.border-red-fix {
  border: 1px solid red;
}

.container-button-shop {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid rgb(30, 135, 255);
  padding: 8px 15px 8px 15px;
  border-radius: 8px;
  background-color: white;
  background: linear-gradient(90deg, rgba(46, 109, 236, 1) 51%, rgb(32, 155, 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 18px;
  cursor: pointer;
}

.shop-image-header {
  height: 80px;
  width: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.describe-header {
  font-size: 13px;
  width: 60%;
  max-width: 1130px;
}

.container-bottom-menu {
  width: 100%;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
  padding: 10px 15px;
  padding-bottom: 20px;
  background-color: white;
}

.user-icon-menu {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  min-height: 30px;
  min-width: 30px;
  margin-right: 15px;
  margin-left: 15px;
}

.img-vedette {
  height: 80px;
  width: 80px;
  margin: 10px;
  margin-right: 5px;
  border-radius: 8px;
  border: 1px solid rgb(192, 192, 192);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-vedette {
  cursor: pointer;
  &:hover {
    .icon-vedette {
      animation: titleVedette 0.5s;
      margin-left: 20px;
    }
  }
}

.icon-vedette {
  margin-left: 10px;
}

@-webkit-keyframes titleVedette {
  0% {
    margin-left: 10px;
  }
  100% {
    margin-left: 20px;
  }
}
@keyframes titleVedette {
  0% {
    margin-left: 10px;
  }
  100% {
    margin-left: 20px;
  }
}

.img-vedette-1 {
  height: 180px;
  margin: 10px;
  margin-right: 5px;
  margin-left: 0;
  width: 180px;
  border-radius: 8px;
  border: 1px solid rgb(192, 192, 192);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* NAVBAR */

.documentation-anchor .ant-affix {
  top: 100px !important;
}

.navbar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 1px 0 3px rgb(226, 226, 226);
  padding-top: 10px;
  padding-bottom: 20px;
  height: 80px;
  animation: displayOpacity 0.5s;
}

.navbar.sticky .logo-menu {
  width: 150px !important;
  height: auto;
  margin: 0;
  margin-top: 5px;
}

.market-navbar.sticky {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 1px 0 3px rgb(226, 226, 226);
  animation: displayOpacity 0.5s;
  width: calc(100vw - 225px) !important;
  padding: 10px 30px;
  .jewel-menu {
    top: 19px !important;
  }
}

.market-navbar.sticky.categories {
  top: 65px;
  right: 0;
  margin-top: 0 !important;
  max-width: none !important;
  box-shadow: 0 1px 1px #e6e6e6 !important;
  padding-bottom: 15px;
}

@keyframes displayOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navbar-container {
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 0px 2px rgb(201, 201, 201);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  height: 70px;
}

/* CARD AFFILIATION */

.card-affialiation {
  width: 70%;
  box-shadow: 0px 0px 2px rgb(189, 189, 189);
  border-radius: 10px;
  padding: 30px;
  height: 295px;
  min-height: 295px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  background-color: rgb(252, 252, 252);
  position: relative;
}

.icon-card-arffialiation {
  width: 90px;
  height: auto;
  margin-right: 20px;
}

.column-middle-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 20px;
  width: 48%;
  height: 100%;
}

.divider-vertical {
  width: 1px;
  height: 100%;
  background-color: rgb(206, 206, 206);
  margin-right: 10px;
}

.divider-horizontal {
  width: 90%;
  margin-left: 5%;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  background-color: rgb(211, 211, 211);
  margin-right: 10px;
}

.number-card-affiliation {
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 6px;
}

.logo-menu {
  width: 130px;
  margin-left: 10%;
  height: auto;
  margin-bottom: 30px;
}

.icon-doc {
  width: auto;
  height: 150px;
  max-width: 100%;
}

.icon-doc-plus {
  width: auto;
  height: 130px;
  padding: 15px;
  max-width: 100%;
  background-color: rgb(87, 87, 87);
  border-radius: 50%;
}

.button-card {
  border: none;
  padding: 8px 11px 8px 11px;
  border-radius: 6px;
  box-shadow: 0px 0px 1.5px rgb(0, 0, 0);
  background: white;
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: rgb(41, 41, 41);
  margin-top: 20px;
}

.describe-card {
  max-width: 90%;
  height: 70%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
}

.text-ellipsis {
  position: relative;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-height: 30px;
  max-width: 95%;
}

#menu-filter-marketplace {
  position: fixed !important;
  top: 20px !important;
  height: 90vh !important;
  z-index: 10;
  max-height: 700px;
}

/* TABLEAU */

.container-tab {
  width: 700px;
  min-height: 500px;
  height: auto;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 3px;
  border: 1px rgb(43, 43, 43) solid;
  margin-left: 50px;
  flex: 1 1 0;
}

.title-tab {
  margin: 0;
  background-color: rgb(212, 212, 212);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-tab {
  display: flex;
  width: 100%;
  height: 45px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  background-color: rgb(233, 233, 233);
}

.text-tab {
  margin-right: 10px;
  margin-left: 30px;
  width: 100px;
  text-decoration: none;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.flag-country {
  width: 30px;
  height: auto;
}

/* PDF VIEWER */

.pdf-viewer {
  height: 80vh;
  width: 95%;
}

.ant-modal.pdf-modal {
  width: 80% !important;
}

.gain-container {
  height: 200px;
  margin-bottom: 30px;
  width: 300px;
  border-radius: 8px;
  padding: 15px;
  border: 1px rgb(212, 212, 212) solid;
  background-color: rgb(252, 252, 252);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 50px;
}

.text-gain {
  font-size: 28px;
  color: rgb(87, 224, 87);
}

.shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}

/* STRIPE */

.stripe-card-input {
  background-color: rgb(84, 134, 228);
  height: 35px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: white;
  font-size: 18px;
}

.inputPricing {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 15px 20px;
  padding: 0;
  border-style: none;
  background-color: rgb(37, 122, 197);
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
  border-radius: 4px;
  height: 50px;
  width: 90%;
  color: white;
  font-family: "Sen", sans-serif;
  text-align: center;
  font-size: 18px;
  color: rgb(255, 255, 255) !important;
}
.inputPricing::placeholder {
  color: white;
  font-size: 18px;
  font-family: "Sen", sans-serif;
}

.inputPricingCard {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 15px 20px;
  padding: 0;
  border-style: none;
  background-color: rgb(37, 122, 197);
  border: rgba(57, 100, 208, 1) 1px solid;
  color: rgb(255, 255, 255) !important;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
  border-radius: 4px;
  height: 50px;
  width: 85%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  font-family: "Sen", sans-serif;
  text-align: center;
  font-size: 18px;
}

.StripeElement.StripeElement--empty {
  color: rgb(255, 255, 255) !important;
  width: 97%;
}

.StripeElement.StripeElement--invalid {
  color: rgb(255, 255, 255) !important;
  width: 97%;
}

.StripeElement.StripeElement--focus {
  width: 97%;
  color: rgb(255, 255, 255) !important;
}

.StripeElement.StripeElement--complete {
  color: rgb(255, 255, 255) !important;
  width: 97%;
}

.button-paid-stripe {
  background-color: rgb(255, 255, 255);
  width: 200px;
  border-radius: 6px;
  color: rgb(37, 122, 197);
  font-size: 18px;
  height: 45px;
  cursor: pointer;
  border: 2px solid rgb(37, 122, 197);
  box-shadow: 1px 1px 3px rgb(182, 182, 182);
}

/* STEP */

.container-step {
  height: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  width: 500px;
  border-radius: 8px;
  padding: 15px;
  border: 1px rgb(212, 212, 212) solid;
  background-color: rgb(252, 252, 252);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.number-step-blue {
  font-size: 28px;
  font-weight: bold;
  color: white;
  background-color: rgb(72, 121, 255);
  border-radius: 50%;
  border: 5px white solid;
  min-height: 65px;
  min-width: 65px;
  height: 65px;
  width: 65px;
  margin-right: 30px;
  font-family: "Comfortaa", cursive, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number-step-orange {
  font-size: 28px;
  font-weight: bold;
  color: white;
  background-color: rgb(255, 178, 107);
  border-radius: 50%;
  border: 5px white solid;
  min-height: 65px;
  min-width: 65px;
  height: 65px;
  width: 65px;
  margin-right: 30px;
  font-family: "Comfortaa", cursive, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-step-be {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  width: 90%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.text-step {
  font-size: 18px;
  font-weight: 400;
  font-family: "Comfortaa", cursive, sans-serif;
}

.divider-step {
  width: 90%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;
  box-shadow: 1px 1px 3px rgb(170, 170, 170);
  margin-right: 10px;
}

.title-step {
  font-weight: 300;
  font-size: 22px;
  color: rgb(32, 32, 32);
}

.title-step-2 {
  font-size: 20px;
  text-align: center;
}

.column-middle-card .title {
  text-align: start;
}

.ant-form.ant-form-horizontal.login-form .title {
  text-align: start;
}

.autocomplete-dropdown-container {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 7px !important;
  box-shadow: 1px 1px 4px rgb(218, 218, 218) !important;
}
.autocomplete-dropdown-container .suggest-location {
  height: 60px !important;
  min-height: 60px !important;
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 7px !important;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(228, 228, 228);
  background-color: rgb(255, 255, 255) !important;
}

.join-illustration1 {
  width: 600px;
  height: auto;
}

.join-illustration2 {
  width: 60%;
  height: auto;
  position: absolute;
  left: 90px;
  top: 100px;
}

.image-join-2 {
  height: 150px;
  width: auto;
}

.ant-upload-list-picture-card-container {
  height: 150px;
  width: 150px;
}

.form-control {
  height: 50px;
}

.react-tel-input .country-list {
  overflow-x: hidden;
}

.plan-top-text-fixed {
  position: absolute;
  top: 0px;
  background: #f9a42c;
  border-radius: 10px;
  font-weight: bold;
  color: #4b4c4c;
  padding: 5px 15px;
}

/* MENU SETTING */

.menu-setting {
  width: 300px;
  background: white;
  box-shadow: 1px 0px 1px rgb(231, 231, 231);
  border-radius: 12px;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.column.reverse {
  flex-direction: column-reverse !important;
}

.ant-select-dropdown {
  z-index: 10000 !important;
}

.ant-select-dropdown {
  z-index: 10000 !important;
}

.item-menu-setting {
  height: 40px;
  font-size: 20px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Cabin", sans-serif;
  font-weight: 300;
  color: rgb(36, 36, 36);
}

.item-menu-setting-active {
  height: 40px;
  font-size: 20px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Cabin", sans-serif;
  font-weight: 300;
  color: white;
  background: linear-gradient(90deg, rgba(46, 109, 236, 1) 51%, rgba(32, 203, 255, 1) 100%);
}

.column-scrolling.wrap .ant-skeleton {
  width: 80vw;
  margin-left: 10vw;
}

.transparent-gradient-button {
  border-radius: 6px;
  border: 2px solid $orange;
  background: $orange;
  -webkit-background-clip: text;
  font-size: 16px;
  color: $orange;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

/* PRODUCT DETAIL */

.big-img-product {
  max-width: 100%;
  max-height: 60vh;
  height: auto;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  background-color: rgb(252, 252, 252);
  box-shadow: 0px 0px 2px rgb(196, 196, 196);
}

.little-img-product {
  width: 90px;
  max-width: 100%;
  max-height: 90px;
  height: auto;
  overflow: hidden;
  background-color: rgb(252, 252, 252);
  border-radius: 8px;
  box-shadow: 0px 0px 2px rgb(196, 196, 196);

  object-fit: cover;
  min-height: 90px;
  vertical-align: top;
  border-radius: 10px;
  margin-bottom: 10px;
}

.container-image-product-detail .ant-image-img {
  margin-right: 10px;
}

.dashed {
  position: absolute;
  top: 48%;
  height: 2px;
  width: 110%;
  background-color: rgb(61, 61, 61);
}

.dashed-2 {
  position: absolute;
  top: 49%;
  height: 3px;
  width: 100%;
  background-color: rgb(61, 61, 61);
  transform: rotate(-7deg);
}

.flag {
  width: 30px;
  height: auto;
  border-radius: 5px;
}

.icon-in-button {
  height: 30px;
  width: auto;
  margin-right: 20px;
  color: white;
}

/* HEADER */

.header {
  width: 100%;
  display: column;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  padding-left: 0px;
  position: absolute;
  top: 0;
  height: 100px;
  padding-top: 40px;
  padding-bottom: 30px;
}

.title-header {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: end;
  height: 100%;
  font-family: "Roboto", sans-serif;
  line-height: 34px;
  height: 35px;
  margin-right: 100px;
  color: rgb(39, 39, 39);
}

.little-title-header {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: end;
  height: 100%;
  font-family: "Roboto", sans-serif;
  line-height: 34px;
  height: 35px;
  margin-right: 100px;
  color: rgb(39, 39, 39);
  margin-bottom: -10px;
}

.column-scrolling.create-product {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}

.button-header {
  border: none;
  padding: 4px 7px 4px 7px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: none;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: rgb(58, 58, 58);
  border: 1px solid rgb(58, 58, 58);
  margin-left: 10px;
  margin-right: 10px;
}

.button-header-active {
  border: none;
  padding: 5px 8px 5px 8px;
  border-radius: 9px;
  box-shadow: 1px 1px 2px rgb(226, 226, 226);
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.container-tag-header {
  position: absolute;
  right: 90px;
  bottom: 45px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
}

.phone-input .ant-select-selector {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  height: 35px !important;
  width: 70px !important;
}

.phone-input .ant-input {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  height: 35px !important;
}

.black {
  color: rgb(41, 41, 41);
}

.black-band {
  width: 85%;
  background-color: rgb(61, 61, 61);
  color: white;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  padding-right: 15px;
  padding-left: 15px;
  font-family: "Cabin", sans-serif;
}

.icon-menu > path {
  color: white;
  fill: "white";
  fill: white;
}

.row.order-supplier.detail {
  background: rgb(247, 247, 247);
  padding: 20px;
  border-radius: 5px;
}

.ant-switch.pricing-switch {
  background: $purpose-2 !important;
  height: 35px;
  width: 100px;
}

.pricing-switch .ant-switch-handle::before {
  height: 31px;
  width: 31px;
  border-radius: 50%;
}

.pricing .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 30px - 2px) !important;
}

.icon-join-us-menu-orange {
  display: none;
}

.button-join-us-menu {
  &:hover {
    color: white;
  }
}

.pricing-switch .ant-switch-inner {
  font-size: 18px;
  font-weight: bold;
}

.ant-radio-wrapper {
  font-size: 15px;
  color: rgb(67, 67, 67);
  font-family: "Montserrat", sans-serif;
}

.row.mobile-shipping.edit-product {
  width: 100% !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.back-gradient {
  width: 100%;
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  padding: 40px;
  align-items: center;
  box-shadow: 0 3px 4px rgb(202, 202, 202);
}

.back-black {
  width: 100%;
  background: black;
  padding: 40px;
  align-items: center;
  box-shadow: 0 3px 4px rgb(202, 202, 202);
}

.title-white {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 30px;
}

.title-white-bold {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.text-white {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 13px;
  margin-top: 15px;
}

.text-white-200 {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 16px;
  font-weight: 200;
}

.green-check-icon-selling-page {
  height: 20px;
  width: auto;
  margin-right: 10px;
  margin-top: 3px;
}

.text-white-bold {
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.button-white-2 {
  font-family: "Roboto", sans-serif;
  color: rgb(70, 70, 70);
  background: white;
  font-size: 23px;
  font-weight: bold;
  padding: 6px 45px 6px 45px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(15, 55, 185, 0.507);
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-switch-checked {
  background: $purpose-2;
}

.ant-switch-blue {
  background: $purpose-2;
}

.settings-right {
  display: flex;
  flex-direction: column;
  width: calc(100% - 350px);
  margin-left: 30px;
}

@media screen and (max-width: 1375px) {
  .settings-right {
    width: 95%;
    margin-top: 30px;
    margin-left: 0;
  }
}

.page.background-landing {
  background-image: url("../images/landing/background3.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.orange-border {
  border: $orange solid 3px;
}

.back-icon-gradient {
  position: absolute;
  width: 64.5px;
  height: 64.5px;
  left: 0px;
  top: 0px;
  border-radius: 15px;
  background: linear-gradient(94.83deg, #0e09f0 0%, #2ba4e8 100%);
  transform: rotate(90deg);
}

.purple {
  color: #7e7bed !important;
}

.purple-back {
  background: #6865e8 !important;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif !important;
  color: #4e4e4e !important;
  font-weight: bold;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #4e4e4e !important;
  text-align: start;
}

.ant-collapse-content-box .text-roboto {
  text-align: start;
}

.column.selling-page {
  width: 100%;
  padding: 40px;
  align-items: center;
}

.title-roboto-bold {
  font-family: "Roboto", sans-serif;
  color: rgb(54, 54, 54);
  font-size: 25px;
  font-weight: bold;
}

.title-roboto {
  font-family: "Roboto", sans-serif;
  color: #585858;
  font-size: 25px;
  font-weight: 400;
}

.title-roboto-300 {
  font-family: "Roboto", sans-serif;
  color: rgb(54, 54, 54);
  font-size: 18px;
  font-weight: 300;
}

.text-roboto {
  font-family: "Roboto", sans-serif;
  color: rgb(54, 54, 54);
  font-size: 16px;
  font-weight: 400;
}

.text-roboto-red {
  font-family: "Roboto", sans-serif;
  color: rgb(233, 30, 30);
  font-size: 16px;
  font-weight: 400;
}

.text-roboto-300 {
  font-family: "Roboto", sans-serif;
  color: rgb(54, 54, 54);
  font-size: 16px;
  font-weight: 300;
}

.text-roboto-bold {
  font-family: "Roboto", sans-serif;
  color: rgb(54, 54, 54);
  font-size: 16px;
  font-weight: bold;
}

.text-roboto-bold-red {
  font-family: "Roboto", sans-serif;
  color: rgb(233, 30, 30);
  font-size: 16px;
  font-weight: bold;
}

.start {
  text-align: start;
  align-items: flex-start;
  justify-content: flex-start;
}

.end {
  text-align: end;
  align-items: flex-end;
  justify-content: flex-end;
}

.center {
  text-align: center;
}

.title-green {
  font-family: "Roboto", sans-serif;
  color: #00ab5e;
  font-size: 22px;
}

.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.container-card-product {
  .text-ellipsis-2 {
    height: 14px !important;
    line-height: 15px !important;
    margin-bottom: 6px;
  }
}

.img-back-gradient {
  background: linear-gradient(90deg, rgba(22, 75, 255, 1) 28%, rgba(32, 136, 255, 1) 91%);
  background-size: cover;
  border-radius: 12px;
  padding: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-right: 20px;
}

.fixed-bottom {
  position: absolute;
  bottom: 30px;
  font-size: 25px;
  width: calc(100vw - 225px);
  margin-left: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 100;
}

.button.order-card {
  padding: 6px 20px 6px 20px;
  position: absolute;
  right: 10px;
  width: 220px;
}

.red {
  color: red !important;
}

.icon-supplier-more {
  width: 3.5vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.container-menu-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: calc(100vw - 225px) !important;
  background-color: white;
  box-shadow: 0 -1px 2px rgb(230, 230, 230);
  height: 40px;
}

.skeleton-95 {
  width: 95%;
}

.illutstration-top-absolute {
  width: 60%;
  height: auto;
}

.custom-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.custom-counter li {
  counter-increment: step-counter;
  margin-bottom: 5px;
}
.custom-counter li::before {
  content: counter(step-counter);
  color: #000000;
  font-weight: 900;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 14pt;
  font-family: "Montserrat";
  font-style: normal;
  margin-right: 10px;
}

.twoChoice {
  width: auto !important;
  padding: 10px !important;
  cursor: pointer !important;
}

.twoChoice-on {
  background: rgb(116, 116, 116);
  color: white;
  width: auto !important;
  padding: 10px !important;
  cursor: pointer !important;
}

.column-scrolling-menu {
  height: calc(100vh - 350px);
  overflow-y: none;
  width: 225px;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 200px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #ffdc9c;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.filter-select {
  background-color: #70c3f3c2;
  border-radius: 5px;
  padding: 6px;
  margin-bottom: 5px;
  color: white;
  font-size: 13px;
  max-width: 100%;
}

.green-box {
  padding: 1px;
  padding-right: 4px;
  padding-left: 4px;
  background: rgba(108, 255, 108, 0.226);
  color: rgb(49, 197, 49);
  font-weight: bold;
  border-radius: 4px;
  border: 1px solid rgb(49, 197, 49);
}

.orange {
  color: $orange;
}

.purple {
  color: $purple;
}

.ant-modal.modal-order .column.paid {
  text-align: end !important;
  align-items: flex-end !important;
}

.absolute-bottom-menu {
  position: absolute;
  bottom: 110px;
  left: 0;
  width: 250px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #f5f9ff;
}

.container-gain-affiliation {
  height: 120px;
  width: 200px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgb(51, 51, 51);
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.download-ebook-button {
  width: 80vw;
  height: 35px;
  background-color: #e83535;
  color: white;
  font-size: 20px;
  border: none;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgb(207, 207, 207);
}

.green {
  color: #6fd979;
}

.back-footer {
  background-image: url("../images/landing/back_footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 350px;
}

.filter-navbar {
  position: absolute;
  right: 0;
}

.container-footer {
  width: 100vw;
}

.card {
  min-width: 230px;
  min-height: 60px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgb(223, 223, 223);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 10px;
}

.blue {
  color: #2a92d5;
}

.ant-modal.modal-1000 {
  width: 1000px !important;
}

.ant-modal.modal-80-vw {
  width: 80vw !important;
}

.ant-modal.modal-full-screen {
  width: 95vw !important;
}

.blog-card {
  margin: 20px;
  width: 300px;
  position: relative;
  height: 300px;
  align-items: center;
  display: flex;
}

.select-manage-offer .ant-select-selector {
  height: 100px !important;
}

.select-manage-offer-2 .ant-select-selector {
  height: 120px !important;
}

.documentation .navbar .row {
  justify-content: flex-start !important;
}

.suggestion-documentation a {
  text-decoration: underline;
}

.content-documentation .c6 {
  color: #313131 !important;
}

.ant-tabs.ant-tabs-top.ant-tabs-mobile.tab-product-details {
  max-width: 100vw;
}

.ant-input-affix-wrapper.marketplace {
  border: none;
  box-shadow: inset 1px 1px 3px rgb(173, 173, 173);
  border-radius: 12px;
  height: 50px;
  background: rgb(250, 250, 250);
  width: calc(100vw - 800px);
  max-width: 1000px;
  .ant-input {
    background: rgb(250, 250, 250);
  }
}

.product-price {
  max-width: 100px;
}

.blur {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  z-index: 10;
  background-color: rgb(0 0 0 / 6%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
}

.image-stack {
  display: grid;
  position: relative; // imperative for the overlapping to work
  grid-template-columns: repeat(1, 1fr);
}

.image-stack__item--bottom {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.image-stack__item--top {
  grid-row: 1;
  grid-column: 1 / 1;
}

.purpose {
  color: #001b76;
}
.purpose-2 {
  color: #3950a4;
}
.purpose-3 {
  color: #5d6eb3;
}

.fixed-modal-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: -1px 0px 3px rgb(206, 206, 206);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(250, 250, 250);
  padding: 20px 5vw;

  width: 1000px;
}

/* MOBILE */

@media screen and (max-width: 1150px) {
  .only-mobile {
    display: flex !important;
  }

  .view-import-list {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5px;
    height: auto;

    p {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .ant-modal-close-x {
    border-bottom: none;
    margin-right: 0;
  }

  .ant-select .ant-select-selector {
    height: 48px !important; /* Changer la hauteur selon vos besoins */
    display: flex !important;
    align-items: center !important; /* Centrer le contenu verticalement */
    justify-content: center !important; /* Centrer le contenu horizontalement */
  }

  .reseller-left {
    display: none;
  }

  .ant-dropdown {
    z-index: 10000;
  }

  .ant-input-affix-wrapper {
    height: auto !important;
  }

  .ant-input {
    min-height: 48px;
  }

  .fixed-modal-footer {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    height: 160px;

    width: 98vw;
    max-width: 98vw;
    padding: 10px;
  }
  #import-product-button-little {
    font-size: 14px;
  }
  .image-stack {
    display: none;
  }
  .row-wrap-mobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: relative;
    flex-wrap: wrap;
  }
  .mobile-display-none {
    display: none;
  }
  .ant-modal.modal-80-vw {
    width: 95vw !important;
  }
  .product-price {
    max-width: auto;
  }
  .recommended-retail {
    text-align: start;
  }
  .resume-plan .card-border {
    width: 210px;
  }
  .ant-modal.modal-full-screen {
    width: 100vw !important;
  }
  #import-product-button {
    font-size: 14px;
    display: none;
    height: 45px;
  }
  .text-product-card-label {
    left: 15% !important;
  }
  .label-product-card {
    width: 100%;
    margin-right: 0;
  }
  .infinite-scroll-component__outerdiv {
    width: 90%;
  }
  .price-product-card {
    width: 100%;
    min-width: 100%;
  }

  .ant-carousel .slick-dots {
    margin-right: 0;
    margin-left: 0;
  }

  .slick-dots.slick-dots-bottom {
    margin-bottom: -50px;
  }

  .content-documentation {
    width: 95vw !important;
    margin-left: 5vw;
  }

  .marketplace-menu {
    top: 40px;
  }
  .board-left-menu {
    padding-top: 0;
  }
  .container-menu {
    height: calc(100vh - 85px);
    box-shadow: none;
  }
  .board-left-menu {
    height: calc(100vh - 239px) !important;
  }

  .video-iframe {
    width: 310px;
    height: 200px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px rgb(199, 199, 199);
  }

  .create-product .ant-select-selector {
    width: 80vw;
  }
  .absolute-bottom-menu {
    background-color: white;
  }

  textarea.ant-input {
    width: 85vw;
  }
  .column-scrolling-menu {
    height: 80vh;
  }
  .column.center {
    padding-right: 10vw;
    padding-left: 10vw;
  }
  .banner-bottom {
    width: 100vw;
    height: 105px;
  }
  .banner-bottom p {
    font-size: 14px;
    width: 70vw;
    text-align: center;
  }
  .banner-bottom .row {
    flex-direction: column;
  }
  .button-banner {
    margin-left: 0px;
    margin-top: 7px;
  }
  .ant-drawer-content {
    overflow-x: hidden;
  }
  .ant-drawer-body {
    padding-left: 0px;
    padding-right: 0px;
    overflow-x: hidden;
  }
  .column-scrolling-menu {
    height: calc(100vh - 350px);
  }

  .illutstration-top-absolute {
    width: 100%;
    height: auto;
  }
  .container-menu-bottom {
    width: 100vw !important;
    bottom: -1px;
  }
  .fixed-bottom {
    width: 100vw;
    margin-left: 0;
  }
  .back-gradient {
    width: 100%;
    padding: 20px;
    padding-left: 20px;
    align-items: flex-start;
  }
  .column.selling-page {
    width: 100%;
    padding: 20px;
    padding-left: 20px;
  }
  .back-black {
    width: 100%;
    padding: 20px;
  }
  p {
    text-align: start;
  }
  .title-300 {
    text-align: start;
  }
  .title-200 {
    text-align: start;
  }
  .title {
    text-align: start;
  }
  .center {
    text-align: center;
  }
  .menu-mobile-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: 80px;
    background-color: #f5f9ff;
    box-shadow: 0px 1px 1px rgb(224, 224, 224);
    z-index: 2;
  }
  .menu-mobile-container.sticky {
    position: fixed;
    top: 0;
    z-index: 10000;
    height: 80px;
    animation: displayOpacity 0.5s;
  }
  .menu-mobile-container.sticky .logo-menu {
    max-height: 40px;
  }
  .logo-menu {
    margin-bottom: 0;
    margin-right: 13%;
    margin-left: 13%;
    width: 40%;
    height: auto;
    max-height: 55px;
  }
  .burger-menu {
    height: 50px;
    width: 50px;
    padding: 6px;
    border-radius: 8px;
    background-color: #ffc590;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px rgb(224, 224, 224);
    cursor: pointer;
  }
  .icon-burger-menu {
    color: white;
    font-size: 35px;
  }
  .page-start {
    flex-direction: column;
  }
  .column-scrolling {
    width: 100%;
    background-color: rgb(250, 250, 250);
    padding: 0;
    padding-top: 30px;
    justify-content: center;
  }
  .column-scrolling.wrap {
    width: 100%;
    background-color: rgb(250, 250, 250);
    padding: 0;
    padding-top: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }
  .bottom-save {
    justify-content: center;
    width: 100vw;
  }
  .container-card-product {
    margin: 0;
    margin-bottom: 0px;
    margin-top: 22px;
    width: 35%;
    margin-right: 7px;
    margin-left: 7px;
    min-width: 160px;
    max-width: 300px;
    height: auto;

    box-shadow: none;
    background: none;

    &:hover {
      -webkit-transform: none;
      transform: none;
    }
  }
  #marketplace-scrolling {
    width: 100vw;
    justify-content: center;
  }
  .button.order-card {
    padding: 6px 20px 6px 20px;
    position: relative;
    width: 100%;
    right: 0;
  }
  .container-card-product .container-image-card {
    height: 40vw !important;
    min-height: auto !important;
    max-height: auto !important;
  }
  .container-card-product {
    width: 40vw !important;
  }
  .product-card-analytic-button-container {
    display: none;
  }
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 130px;
    height: auto !important;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
  }
  .input-header {
    position: relative;
    right: auto;
    margin-left: 15%;
  }
  .title-header {
    font-size: 22px;
    color: rgb(54, 54, 54);
    max-width: 90%;
    display: inline-block;
    margin-right: 0;
  }
  .row-inventory {
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin-bottom: 60px;
    margin-top: 30px;
    border: none;
  }
  .reseller.row-inventory {
    height: auto;
    margin-bottom: 0px;
  }
  .banner-extension {
    width: 95%;
    margin-top: 0;
  }

  .row-inventory .row {
    width: 90% !important;
    margin-bottom: 10px;
  }
  .row-inventory .column {
    flex-direction: column;
    align-items: center !important;
    width: 100% !important;
  }
  .row-inventory .column.second {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .row-inventory .title.text-ellipsis {
    max-width: 100% !important;
  }
  .row-inventory .column.second .title-200 {
    font-size: 14px;
    font-weight: 300;
    text-align: start;
  }
  .row-inventory .column.second .title {
    font-size: 16px;
    text-align: start;
  }
  .row-inventory .column.second .row {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }
  .row-inventory .button {
    width: 80% !important;
  }
  .row-inventory .button-white {
    width: 80% !important;
  }
  .row-inventory .button-disable {
    width: 80% !important;
  }
  .row-inventory .button-red {
    width: 80% !important;
  }
  .product-detail .button {
    width: 100% !important;
  }
  .product-detail .transparent-gradient-button {
    width: 100% !important;
  }
  .product-detail .column-scrolling {
    padding-bottom: 40px;
  }
  .button-absolute-top {
    position: relative;
    top: auto;
    left: auto;
    width: 90%;
    max-width: 450px;
    margin-left: 5%;
    margin-bottom: 20px;
    padding: 10px 18px 10px 18px;
    font-size: 18px;
  }
  .button-absolute-top-right {
    position: relative;
    top: auto;
    left: auto;
    width: 90%;
    max-width: 450px;
    margin-left: 5%;
    margin-bottom: 20px;
  }
  .button-absolute {
    bottom: 20px;
    right: 15px;
    padding: 10px 18px 10px 18px;
  }
  .order-card .column {
    align-items: flex-start !important;
  }
  .order-card .image-product-inventory {
    width: auto !important;
    max-width: 90% !important;
    height: auto !important;
    max-height: 90% !important;
  }
  .ant-drawer-open {
    z-index: 10000;
  }
  .ant-drawer {
    padding-bottom: 0;
    top: 0 !important;
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
  }
  .ant-drawer-content {
    min-height: 100vh;
  }
  .ant-drawer-body {
    padding: 30px;
    padding-top: 80px;
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;

    .container-menu {
      width: 100%;
    }
  }
  .ant-drawer-wrap {
    z-index: 10000;
    width: 100vw !important;
    overflow-x: hidden;
  }
  .ant-drawer-close {
    background: white;
    width: fit-content;
    font-size: 30px;
  }
  .ant-drawer-header {
    position: fixed;
    width: calc(100vw);
    left: 0;
    top: 0;
    border-radius: 0;
    z-index: 1000;
    height: 55px;
    background: white;
  }
  .ant-modal {
    padding: 0 !important;
    padding-bottom: 0;
    top: 0 !important;
    width: 100vw !important;
    min-width: 100vw !important;
    max-width: 100vw !important;
    margin: 0 !important;
  }
  .ant-modal-content {
    min-height: 100vh;
    width: 100vw;
  }
  .ant-modal-body {
    padding: 30px;
    padding-top: 80px;
  }
  .ant-modal-wrap {
    z-index: 10000;
    width: 100vw !important;
    overflow-x: hidden;
  }
  .ant-modal-close-icon {
    font-size: 30px;
    margin-top: 10px;
  }
  .ant-modal-close {
    left: 0 !important;
    padding-left: 10px;
    background: white;
    width: fit-content;
    height: 55px;
    position: fixed;
    z-index: 1000;
    width: 100vw;
  }
  .ant-modal-header {
    position: fixed;
    z-index: 1000;
    width: calc(100vw - 135px);
    right: 0;
    border-radius: 0;
  }
  .ant-modal.modal-order .row {
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start !important;
  }
  .ant-modal.modal-order .column {
    flex-direction: column;
    align-items: flex-start !important;
    text-align: start !important;
  }
  .ant-modal.modal-order .column.paid {
    text-align: end !important;
    align-items: flex-end !important;
  }
  .ant-modal.modal-order {
    width: 95% !important;
    min-width: auto !important;
    max-width: 95% !important;
  }
  .popup-order .image-product-inventory {
    width: 100%;
    margin-right: 0;
    height: auto;
    max-height: 100vh;
  }
  .column.popup-order p {
    text-align: start !important;
  }
  .image-product-inventory {
    width: auto;
    max-width: 50vw;
  }
  .button-paid-stripe {
    width: 60%;
    margin-left: 20%;
  }
  .button.tag {
    width: auto !important;
    min-width: 80px !important;
    max-width: auto !important;
    margin-left: 22px;
    margin-right: 12px;
  }
  .menu-setting .row {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 7%;
    padding-right: 5%;
  }
  .title-menu-setting {
    font-size: 20px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    max-width: 90vw;
  }
  .menu-setting .divider-horizontal {
    margin-top: 15px !important;
  }
  .arrow-menu-setting {
    font-size: 25px;
    position: absolute;
    right: 25px;
    color: rgb(95, 95, 95);
  }
  .column-scrolling.documentation {
    padding: 20px;
  }
  .column-scrolling.documentation .row {
    flex-direction: column;
    width: 100%;
  }
  .column-scrolling.documentation .column {
    flex-direction: column;
    width: 100% !important;
  }
  .column-scrolling.documentation .title-200 {
    min-height: auto !important;
    margin-bottom: 10px !important;
    text-align: center;
  }
  .column-scrolling.documentation .title-300 {
    min-height: auto !important;
    margin-bottom: 10px !important;
    text-align: center;
  }
  .column-scrolling.documentation .text-gradient {
    margin-bottom: 50px !important;
  }
  .column-scrolling.documentation .row.contact-us {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    flex-direction: row !important;
    margin-bottom: 15px !important;
    margin-top: 0px !important;
  }
  .column-scrolling.documentation .row.contact-us .text-gradient {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }
  .row.header-setting {
    flex-direction: row !important;
  }
  .row.header-setting .title-200 {
    margin-bottom: 0 !important;
  }
  .column .location-search-input {
    width: 85vw !important;
  }
  .your-information .title-300 {
    width: 85vw !important;
  }
  .your-information .ant-input {
    min-width: 100% !important;
  }
  .react-tel-input .form-control {
    width: 100% !important;
  }
  .your-information .ant-input.little-input {
    width: 40vw !important;
  }
  .PhoneInputInput {
    width: 55vw !important;
  }
  .column-scrolling.join-us {
    width: 95%;
    padding: 30px;
  }
  .column-scrolling.join-us .title-300 {
    max-width: 85vw !important;
  }
  .column-scrolling.join-us .title-200 {
    margin-left: 20px;
  }
  .column-scrolling.join-us .ant-input {
    max-width: 85vw !important;
  }
  .column-scrolling.join-us .ant-input-password {
    max-width: 85vw !important;
  }
  .illustrations-join {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .join-illustration1 {
    width: 55vw;
  }
  .join-illustration2 {
    width: 45vw;
    top: 50px !important;
    left: 150px !important;
  }
  .menu-setting {
    align-items: center !important;
    justify-content: center !important;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
  }
  .item-menu-setting-active {
    width: 40%;
  }
  .item-menu-setting {
    width: 40%;
  }
  .row.order-supplier {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center;
    width: 85% !important;
    margin-left: 10%;
  }
  .row.order-supplier .column {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .row.order-supplier .image-product-inventory {
    margin-right: 0;
  }
  .shop-supplier .shop-name-input {
    width: 100% !important;
  }
  .shop-supplier .describe-input {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    width: 120px;
    height: 120px;
  }
  .mobile-shipping {
    width: 95%;
    margin-bottom: 20px;
  }
  .mobile-shipping .column {
    width: 80%;
  }
  .ant-modal .column.warning-delete-shipping {
    align-items: center !important;
    padding: 30px;
  }
  .ant-modal .column.warning-delete-shipping .container-card-product {
    width: auto;
    max-width: 90%;
  }
  .page-start.menu-setting.container-setting-menu {
    width: 100% !important;
    justify-content: flex-start !important;
  }
  .column-scrolling.documentation .title-300.or-doc {
    margin-bottom: 0 !important;
  }
  .row.create-product .column {
    width: 95% !important;
    height: auto !important;
  }
  .row.create-product .column .ant-upload {
    width: 80vw;
    height: 40vh;
    border: 1px solid rgb(116, 188, 255);
    border-radius: 8px;
  }
  .row.create-product .column .ant-input {
    width: 80vw;
  }
  .row.create-product .column .ant-input.describe-input {
    height: 250px !important;
  }
  .row.create-product {
    height: auto !important;
  }
  .row.create-product .column .ant-input.describe-input {
    margin-bottom: 30px;
  }
  .option-bottom {
    position: relative;
    top: auto;
    margin-top: 60px;
  }
  .column-scrolling.product-detail .button-red {
    width: 100% !important;
  }
  .column-scrolling.create-product {
    width: 100% !important;
    padding-left: 0 !important;
    padding: 25px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .button-absolute {
    z-index: 100;
  }
  .image-join-2 {
    width: 100%;
    height: auto;
  }
  .invitation .ant-modal-body {
    padding-top: 50px;
    margin-bottom: 40px;
  }
  .invitation .button-in-input {
    font-size: 11px;
  }
  .black-band {
    text-align: center;
    height: 80px;
  }
  .checkbox-on-product {
    top: 15px;
    left: 8px;
  }
  .container-img-inventory-product.order {
    min-width: auto;
    width: 80%;
    display: flex;
    align-items: flex-start;
    height: auto;
    padding-top: 10px;
  }
  .container-img-inventory-product {
    min-width: auto;
    width: 80%;
    display: flex;
    align-items: flex-start;
    height: auto;
    padding-top: 10px;
  }
  .card-accompte-not-valid {
    width: 90%;
    margin-left: 5%;
  }
  .text-menu-active {
    width: calc(100% + 54px);
  }
  .column.text-end p {
    text-align: end !important;
    width: 100%;
  }
}

.hover-up {
  transition: transform 0.5s;
}

.hover-up:hover {
  transform: translateY(-0.3vw);
}
.faded-img {
  transition: opacity 0.5s;
}

#fader:hover .faded-img {
  opacity: 0.9;
}

.shadow2 {
  box-shadow: 1px 1px 2px rgba(129, 129, 129, 0.418);
}

.shadow::after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.shadow:hover::after {
  opacity: 1;
}

.box-shadow {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box-shadow::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box-shadow:hover {
  -webkit-transform: scale(1.08, 1.08);
  transform: scale(1.08, 1.08);
}

.box-shadow:hover::after {
  opacity: 1;
}
.darken {
  -webkit-transition: 0.6s;
  transition: 0.6s;
}
.darken:hover {
  filter: brightness(120%);
}

@media screen and (max-width: 820px) {
  .inventory {
    .ant-input {
      width: 100%;
    }
  }
}
