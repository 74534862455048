.payment-page {
  width: 100%;
  padding: 0 13vw;
  padding-bottom: 100px;
  overflow-x: hidden;
  padding-top: 80px;
  position: relative
}

.img {
  width: 230px;
  margin-right: 30px;
}

.payment-page .course-navbar {
  box-shadow: 0 2px 2px rgb(228, 228, 228) !important;
  max-width: 100vw;
  left: 0;
  position: absolute;
  top: 0;
}

.content-course {
  width: calc(100% - 420px);
  min-width: 600px;
  margin-right: 30px;
}

.left-column {
  width: 45%;
  min-width: 330px;
}

.right-column {
  width: 45%;
  min-width: 330px;
}

.input-pricing-course-payment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 0 0;
  padding: 0;
  border-style: none;
  background-color: rgb(255, 255, 255);
  border: rgb(85, 85, 85) 1px solid;
  color: rgb(63, 63, 63) !important;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #cccccc;
  border-radius: 4px;
  height: 50px;
  padding-left: 2.5%;
  padding-right: 2.5%;
  font-family: "Sen", sans-serif;
  text-align: center;
  font-size: 18px;
  width: 100%;
  min-width: 320px;
  max-width: 500px;
}

.payment-button {
  width: 100%;
  min-width: 320px;
  max-width: 500px;
  font-size: 18px !important;
  margin-top: 20px;
}

.paypal-button-course {
  width: 100%;
  min-width: 320px;
  max-width: 500px;
  font-size: 18px !important;
  margin-top: 20px;
}

.paypal-button-row.paypal-button-number-0.paypal-button-layout-vertical.paypal-button-shape-rect.paypal-button-number-multiple.paypal-button-env-sandbox.paypal-button-color-gold.paypal-button-text-color-black.paypal-logo-color-blue {
  display: none !important;
}

@media screen and (max-width: 1100px) {
  .left-column {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1300px) {
  .content-course {
    min-width: 80vw;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
