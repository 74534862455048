.resume-plan {
  width: 100%;
  padding: 0 5vw;
  .card-border {
    width: 350px;
    border-radius: 8px;
    border: 1px solid rgb(177, 177, 177);
    padding: 10px 15px;
    position: relative;
    cursor: pointer;
  }
  .percentage {
    background: #ffb56f;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    color: white;
    font-weight: bold;
    position: absolute;
    top: -10px;
    right: -15px;
    padding: 5px 9px;
  }
}
