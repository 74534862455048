.tab-1{
    min-width: 90px;
    width: 12%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.tab-2{
    min-width: 80px;
    width: 10%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.tab-3{
    min-width: 180px;
    width: 25%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.tab-4{
    min-width: 60px;
    width: 7%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.tab-5{
    min-width: 80px;
    width: 10%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.tab-6{
    min-width: 80px;
    width: 10%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.tab-7{
    min-width: 90px;
    width: 10%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.tab-8{
    min-width: 40px;
    width: 5%;
    display: flex;
    justify-content: flex-start;
    margin-right: 15px;
    align-items: center;
}

.row-tab{
    border-bottom: 1px solid rgb(223, 223, 223);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 25px;
    padding-right: 15px;
    min-width: 860px;
}

.tab-container{
    width: 90%;
    overflow-x: scroll;
    border-radius: 8px;
    box-shadow: 2px solid rgb(192, 192, 192);
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
}

.red-container{
    padding: 10px;
    padding-left: 20px;
    border-radius: 8px;
    border: 2px solid rgb(248, 86, 86);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: rgb(248, 86, 86);
    font-size: 33px;
    flex-direction: column;
    width: 200px;
    font-weight: 300;
}

.tab-head{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: rgb(226, 226, 226);
    border-bottom: 1px solid rgb(177, 177, 177);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 25px;
    padding-right: 15px;
    min-width: 860px;
    
}