.double-switch {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;

  .ant-switch-blue {
    margin: 0 15px;
  }
}

@media screen and (max-width: 1150px) {
  .double-switch {
    flex-direction: column;

    .ant-switch-blue {
      margin: 10px;
    }
  }
}
