.shipping {
  .section-1 {
    width: 20%;
    min-width: 110px;
    margin: 2%;
  }
  .section-2 {
    width: 20%;
    min-width: 150px;
    margin: 2%;
  }
  .section-3 {
    width: 15%;
    min-width: 160px;
    margin: 2%;
  }
  .section-4 {
    width: 15%;
    min-width: 140px;
    margin: 2%;
  }
  .section-5 {
    width: 20px;
    margin: 2%;
    min-width: 60px;
  }
}
.tooltip {
  background: white;
  width: 140px;
  position: absolute !important;
  z-index: 100;
  border-radius: 8px;
  align-items: center !important;
  box-shadow: 1px 1px 3px #bdbdbd;
  padding: 15px;
}

@media screen and (max-width: 1375px){
  .card.shipping{
    overflow-x: scroll;
    padding: 20px;
  }
  .shipping.create-edit{
    overflow-x: scroll;
  }
  .section-5 {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
}
