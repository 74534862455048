.course-container {
  width: 100vw;
  max-width: 100%;
  padding: 0 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-x: hidden;
}

.course-black-banner {
  width: 100vw;
  background: black;
  padding: 20px 13vw;
  font-size: 25px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.course-navbar {
  background: white;
  box-shadow: 0 2px 2px rgb(0, 0, 0);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
}

@media screen and (max-width: 1100px) {
  .course-container {
    padding: 0 5vw;
  }
}
