.select-container {
    position: relative;
    width: 300px;
    height: 30px;
  
    select {
      position: absolute;
      display: flex;
      opacity: 0;
      width: 100%;
      height: 100%;
  
      option {
        white-space: pre;
      }
  
      &:hover {
        cursor: pointer;
      }
    }
  
    button {
      font-size: 14px;
      display: flex;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      border: 1px solid rgb(121, 121, 121);
      border-radius: 5px;
      background-color: white;
      height: 30px;
      padding: 5px;
  
      &.hover {
        background-color: rgb(243, 243, 243);
      }
    }
  }
  
  .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid rgb(185, 185, 185);
    margin-right: 6px;
  }