.synchronization {
  img {
    width: 40%;
    margin-right: 5%;
  }
}
.home-container {
  width: 100%;
  padding-right: 15vw;
  padding-left: 15vw;
}

.advices-container {
  width: 100%;
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  p {
    font-family: "Montserrat", sans-serif !important;
    color: rgb(136, 136, 136);
  }

  .card {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    margin: 15px;
    height: fit-content;
    height: 290px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &::-webkit-scrollbar {
    height: 6px !important;
  }
}

.landing {
  .marketplace-label {
    width: 12vw;
    margin: 10px;
    max-width: 250px;
  }
  .home-container {
    width: 100%;
    padding-right: 15vw;
    padding-left: 15vw;
  }
  .site-collapse-custom-collapse {
    width: 600px;
    max-width: 90vw;
    margin-top: 35px;
  }
  .median-div {
    width: 45%;
  }
  .unique-products {
    li {
      line-height: 26px;
      color: rgb(88, 88, 88) !important;
    }
    ul {
      padding-left: 20px;
    }
  }
  .sustainable-container {
    background: url(../../images/illustrations/home/back_sustainable.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-left: 15vw;
    padding-right: 15vw;
    padding-top: 10vw;
    padding-bottom: 50px;
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    .center {
      text-align: center !important;
    }
  }
  .sustainable-card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 40px;
  }
  .sustainable-card {
    background: white;
    border-radius: 8px;
    box-shadow: 1px 2px 4px rgb(202, 202, 202);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 240px;
    height: 220px;
    margin: 10px;
  }
  .speed-up-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    img {
      height: 70px;
      margin-bottom: 15px;
    }

    .row-wrap {
      margin: 15px;
      width: 30%;
      align-items: flex-start;
    }
  }

  .automate {
    li {
      margin-bottom: 5px;
    }
  }
  .connect-your-store {
    li {
      margin-bottom: 5px;
    }
  }
  .find-unique-product {
    li {
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .landing {
    .home-container {
      width: 100%;
      padding-right: 7vw;
      padding-left: 7vw;
      flex-direction: column;

      .median-div {
        width: 100%;
      }
    }
    .marketplace-label {
      width: 90%;
      margin: 6px;
      max-width: 200px;
    }
    .labels.home-container {
      justify-content: space-around;
      margin-top: 20px;
    }
    .speed-up-container {
      img {
        margin-right: 20px;
      }
      .row-wrap {
        width: 100%;
      }
    }
  }
  .synchronization {
    img {
      width: 90%;
      margin: 0 5%;
    }
  }
  .sustainable-card-container {
    flex-direction: column;
  }
  .labels.home-container {
    flex-direction: row;
  }
}
