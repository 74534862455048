.collab .user-card {
  padding: 10px;
  background: rgb(250 250 250);
  border-radius: 6px;
  border: 1px solid rgb(184, 184, 184);
  margin: 10px;
  align-items: flex-start;
  width: 100%;
  margin-top: 30px;
}

.collab .content-scroll {
  height: 1000px;
  overflow: scroll;
  display: flex;
  justify-content: flex-start;
  width: 90vw;
  margin-top: 40px;
}
.collab {
  align-items: center !important;
}
