.advanced-label {
  padding: 6px 15px;
  border-radius: 8px;
  font-size: 14px;
  color: white;
  background-color: #9a70f38e;
  font-weight: 600;
  cursor: pointer;
}

.marketplace-home {
  .z-index-1000{
    z-index: 1000 !important;
    position: absolute;
    top: 0;
  }
  .category-divider {
    width: 90%;
    height: 2px;
    background: rgb(228, 228, 228);
    box-shadow: 0.5px 0.5px 2px rgb(235, 235, 235);
    margin: 15px 0;
  }
  .card {
    padding-top: 30px;
    max-width: 1300px;
  }
  .button-grey {
    border-radius: 6px;
    color: white;
    box-shadow: 0.5px 0.5px 3px rgb(224, 224, 224);
    font-weight: 600;
    cursor: pointer;
    border: none;
    background-color: #b5b5b5;
    padding: 6px 60px;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
    margin: 10px 0;
  }
  .category {
    border-radius: 6px;
    box-shadow: 1px 1px 2px rgb(211, 211, 211);
    font-weight: 700;
    font-size: 16px;
    color: rgb(65, 65, 65);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;
    max-height: 55px;
    border: 1px solid rgb(240, 240, 240);
    position: relative;
    margin: 12px;
    padding-left: 70px;

    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    &::after {
      content: "";
      border-radius: 8px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 3px 6px rgba(173, 173, 173, 0.3);
      opacity: 0;
      -webkit-transition: all 0.6s cubic-bezier(0.125, 0.64, 0.4, 1);
      transition: all 0.6s cubic-bezier(0.125, 0.64, 0.4, 1);
    }

    &:hover {
      -webkit-transform: scale(1.03, 1.03);
      transform: scale(1.03, 1.03);
    }
    img {
      position: absolute;
      height: 37px;
      left: 8px;
    }
  }
}

@media screen and (max-width: 1150px) {
  .marketplace-home {
    .card {
      width: 100%;
      padding: 10px;
      margin: 0;
    }
    .product-container-marketplace-home {
      padding: 0 !important;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}
