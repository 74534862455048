.product-link {
  margin-bottom: 30px;
  flex-wrap: wrap;
  .part {
    width: 40%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .product-card {
    padding: 10px 15px;
    border-radius: 5px;
    background: rgb(247, 247, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-right: 40px;
  }

  .product-title {
    max-height: 85px;
    overflow: hidden;
    font-size: 13px;
  }

  .arrow {
    width: 20px;
    margin-top: 40px;
  }

  .product-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    height: 22px;
    width: 22px;
    box-shadow: 1px 1px 2px rgb(209, 209, 209);
    border-radius: 4px;
    background-color: #ff9a39;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.product-link div .product-img {
  margin-right: 15px !important;
  border-radius: 4px !important;
  height: 85px !important;
  width: 85px !important;
  max-width: 85px !important;
  min-width: 85px !important;
  overflow: hidden !important;
}

.product-link a .product-img {
  margin-right: 15px !important;
  border-radius: 4px !important;
  height: 85px !important;
  max-width: 85px !important;
  width: 85px !important;
  min-width: 85px !important;
  overflow: hidden !important;
}

.product-link .product-img img {
  object-fit: contain;
  border-radius: 4px !important;
}

@media screen and (max-width: 1150px) {
  .product-link {
    flex-direction: column !important;
    .part {
      margin: 0 15px;
      width: 100%;
      margin-left: 0;
      max-width: 80vw;
    }
    .arrow {
      margin: 25px !important;
      transform: rotate(90deg);
    }
  }
  .arrow {
    margin: 25px !important;
    transform: rotate(90deg);
  }

  .mapping-variant {
    margin-bottom: 50px;
  }
}
