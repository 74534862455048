.fixed-paid-feature {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media screen and (max-width: 1150px) {
  .fixed-paid-feature {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 20px;
  }
}
