.header-product-analytic {
  .feature {
    margin: 0;
    width: calc(33% - 15px);
  }
  .illustration-1 {
    width: 20vw;
    margin: 0 15px 15px 15px;
    max-width: 400px;
  }
  .content-text-header{
      width: 60%;
  }
}

@media screen and (max-width: 1150px) {
  .header-product-analytic {
    .feature {
      width: 90%;
      margin: 20px;
    }
    .illustration-1 {
      width: 90%;
      margin-bottom: 20px;
    }
    .content-text-header{
        width: 100%;
    }
  }
}
