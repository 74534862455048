.analytic-button {
  opacity: 0.7;
  position: absolute;
  top: 15px;
  right: 12px;
  padding: 10px 15px;
  background: #c8c9cc;
  border-radius: 6px;

  p {
    color: #4b4c4c;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    width: 20px;
    margin-left: 10px;
  }

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1150px) {
  .analytic-button {
    p {
      display: none;
    }
    img {
      margin-left: 0px;
    }
  }
}
