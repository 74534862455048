

span.menu {
	position: absolute;
	left: 125%;
	top: 7px;
	font-size: 18px;
	font-family: 'Comfortaa', cursive, sans-serif;
	letter-spacing: 1px;
	color: #fafafa;
}

.container-button-burger {
	position: absolute;
	top: 130px;
	left: 90px;
	margin: -20px 0 0 -65px; 
	width: 125px;
	height: 50px;
	background: #ff7c01af; /* Old browsers */
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 1px 1px 1px rgb(218, 218, 218);
    padding: 0 !important;
    padding-left: 10px !important;
    z-index: 1000;
}

.container-button-burger input {
	display: none;
}

.container-button-burger label {
	position: relative;
	width: 40px;
	height: 40px;
	display: block;
	cursor: pointer;
	background: transparent;
}

/* Exit Icon */

.container-button-burger label:before,
.container-button-burger input:checked + label:before {
	content: '';
	position: absolute;
	top: 50%;
	margin-top: -2px;
	width: 40px;
	height: 4px;
	border-radius: 2px;
	background: #fafafa;
}

.container-button-burger label:before {
	-webkit-animation: animationOneReverse 1s ease forwards;
	animation: animationOneReverse 1s ease forwards;
}

@-webkit-keyframes animationOneReverse {
	0% { -webkit-transform: rotate(315deg); }
	25% { -webkit-transform: rotate(360deg); }
	50%, 100% { -webkit-transform: rotate(0deg); }
}
@keyframes animationOneReverse {
	0% { transform: rotate(315deg); }
	25% { transform: rotate(360deg); }
	50%, 100% { transform: rotate(0deg); }
}

.container-button-burger input:checked + label:before {
	-webkit-animation: animationOne 1s ease forwards;
	animation: animationOne 1s ease forwards;
}

@-webkit-keyframes animationOne {
	0%, 50% { -webkit-transform: rotate(0deg); }
	75% { -webkit-transform: rotate(360deg); }
	100% { -webkit-transform: rotate(315deg); }
}
@keyframes animationOne {
	0%, 50% { transform: rotate(0deg); }
	75% { transform: rotate(360deg); }
	100% { transform: rotate(315deg); }
}

.container-button-burger label:after,
.container-button-burger input:checked + label:after {
	content: '';
	position: absolute;
	top: 50%;
	margin-top: -2px;
	width: 40px;
	height: 4px;
	border-radius: 2px;
	background: #fafafa;
}

.container-button-burger label:after {
	-webkit-animation: animationTwoReverse 1s ease forwards;
	animation: animationTwoReverse 1s ease forwards;
}

@-webkit-keyframes animationTwoReverse {
	0% { -webkit-transform: rotate(405deg); }
	25% { -webkit-transform: rotate(450deg); }
	50%, 100% { -webkit-transform: rotate(0deg); }
}
@keyframes animationTwoReverse {
	0% { transform: rotate(405deg); }
	25% { transform: rotate(450deg); }
	50%, 100% { transform: rotate(0deg); }
}

.container-button-burger input:checked + label:after {
	-webkit-animation: animationTwo 1s ease forwards;
	animation: animationTwo 1s ease forwards;
}

@-webkit-keyframes animationTwo {
	0%, 50% { -webkit-transform: rotate(0deg); }
	75% { -webkit-transform: rotate(450deg); }
	100% { -webkit-transform: rotate(405deg); }
}
@keyframes animationTwo {
	0%, 50% { transform: rotate(0deg); }
	75% { transform: rotate(450deg); }
	100% { transform: rotate(405deg); }
}

/* Burger Icon */

.container-button-burger label .burger:before {
	content: '';
	position: absolute;
	top: 6px;
	width: 40px;
	height: 4px;
	border-radius: 2px;
	background: #fafafa;
	-webkit-animation: animationBurgerTopReverse 1s ease forwards;
	animation: animationBurgerTopReverse 1s ease forwards;
}

@-webkit-keyframes animationBurgerTopReverse {
	0%, 50% { -webkit-transform: translateY(12px); opacity: 0; }
	51% { -webkit-transform: translateY(12px); opacity: 1; }
	100% { -webkit-transform: translateY(0px); opacity: 1; }
}
@keyframes animationBurgerTopReverse {
	0%, 50% { transform: translateY(12px); opacity: 0; }
	51% { transform: translateY(12px); opacity: 1; }
	100% { transform: translateY(0px); opacity: 1; }
}

.container-button-burger input:checked + label .burger:before {
	-webkit-animation: animationBurgerTop 1s ease forwards;
	animation: animationBurgerTop 1s ease forwards;
}

@-webkit-keyframes animationBurgerTop {
	0% { -webkit-transform: translateY(0px); opacity: 1; }
	50% { -webkit-transform: translateY(12px); opacity: 1; }
	51%, 100% { -webkit-transform: translateY(12px); opacity: 0; }
}
@keyframes animationBurgerTop {
	0% { transform: translateY(0px); opacity: 1; }
	50% { transform: translateY(12px); opacity: 1; }
	51%, 100% { transform: translateY(12px); opacity: 0; }
}

.container-button-burger label .burger:after {
	content: '';
	position: absolute;
	bottom: 6px;
	width: 40px;
	height: 4px;
	border-radius: 2px;
	background: #fafafa;
	-webkit-animation: animationBurgerBottomReverse 1s ease forwards;
	animation: animationBurgerBottomReverse 1s ease forwards;
}

@-webkit-keyframes animationBurgerBottomReverse {
	0%, 50% { -webkit-transform: translateY(-12px); opacity: 0; }
	51% { -webkit-transform: translateY(-12px); opacity: 1; }
	100% { -webkit-transform: translateY(0px); opacity: 1; }
}
@keyframes animationBurgerBottomReverse {
	0%, 50% { transform: translateY(-12px); opacity: 0; }
	51% { transform: translateY(-12px); opacity: 1; }
	100% { transform: translateY(0px); opacity: 1; }
}

.container-button-burger input:checked + label .burger:after {
	-webkit-animation: animationBurgerBottom 1s ease forwards;
	animation: animationBurgerBottom 1s ease forwards;
}

@-webkit-keyframes animationBurgerBottom {
	0% { -webkit-transform: translateY(0px); opacity: 1; }
	50% { -webkit-transform: translateY(-12px); opacity: 1; }
	51%, 100% { -webkit-transform: translateY(-12px); opacity: 0; }
}
@keyframes animationBurgerBottom {
	0% { transform: translateY(0px); opacity: 1; }
	50% { transform: translateY(-12px); opacity: 1; }
	51%, 100% { transform: translateY(-12px); opacity: 0; }
}