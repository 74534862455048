.review-label {
  z-index: 2;
  background-color: #272727;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 6px;
  box-shadow: 0.5px 0.5px 1px #272727;

  .text.total {
    color: #dcdcdc;
    font-size: 11px;
  }

  .text.score {
    color: rgb(237, 237, 237);
    font-size: 11px;
    max-height: 15px;
  }

  .ant-rate-star-first {
    color: #8a8a8a;
  }
  .ant-rate-star-second {
    color: #8a8a8a;
  }

  .ant-rate-star-full {
    .ant-rate-star-first {
      color: #fff;
    }
    .ant-rate-star-second {
      color: #fff;
    }
  }

  .ant-rate-star-half {
    .ant-rate-star-first {
      color: #fff;
    }
  }
}
