p {
  font-family:"Montserrat", sans-serif !important;
  font-weight: 400;
}
h3{
  font-family:"Montserrat", sans-serif !important;
  font-weight: 700;
}
h4{
  font-family:"Montserrat", sans-serif !important;
}
h2{
  font-family:"Montserrat", sans-serif !important;
  font-weight: 800;
}
.course-banner {
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.course-banner-img {
  width: 100%;
  height: 330px;
  object-fit: cover;
}

.course-banner-content {
  position: absolute;
  left: 20vw;
  top: 8%;
  max-width: 400px;
}

.course-banner h1 {
  font-family: "Archivo Narrow", sans-serif;
  line-height: 47px;
}

.step-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.product-img {
  width: 45%;
  min-width: 350px;
  margin-right: 20px;
}

.course-right-container {
  width: 45%;
  min-width: 350px;
  margin-left: 20px;
}

.course-left-container {
  width: 45%;
  min-width: 350px;
  margin-left: 20px;
}

.course-items-right-container {
  width: 40%;
  min-width: 280px;
  margin-left: 20px;
}

.course-items-left-container {
  width: 40%;
  min-width: 280px;
  margin-left: 20px;
}

.item-img {
  width: 90px;
  margin-right: 30px;
}

.course-buy-banner-container {
  background: #fba95e;
  width: 100vw;
  position: relative;
  display: flex;
  justify-content: end;
  box-sizing: border-box;
  margin-top: 80px;
  padding: 30px 13vw;
}

.course-buy-banner-img {
  width: 45%;
  min-width: 280px;
  margin-right: 30px;
  margin-top: -80px;
  max-height: 450px;
}

@media screen and (max-width: 1100px) {
  .course-banner-content {
    top: 3vw;
    left: 5vw;
    padding: 15px;
    background: #00000063;
    max-width: 100%;
    margin-right: 10px;
  }
  .course-banner-img {
    max-height: 320px;
    min-height: 300px;
  }
  .course-banner h1 {
    font-size: 30px !important;
    line-height: 37px;
  }
  .step-container {
    flex-wrap: wrap;
  }
  .course-right-container {
    margin-left: 0;
    width: 90%;
    margin-top: 30px;
    min-width: 260px;
  }
  .course-left-container {
    min-width: 260px;
  }
  .product-img {
    width: 90%;
    margin-right: 0;
    min-width: 230px;
    max-width: 400px;
  }
  .course-items-right-container {
    margin-left: 0;
    width: 100%;
    margin-top: 30px;
    margin-right: 0;
  }
  .course-items-left-container {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .step-container {
    justify-content: flex-start;
  }
  .course-buy-banner-container {
    flex-direction: column;
    padding: 20px;
  }
}
