.card-connect-shop {
  width: 350px;
  border-radius: 8px;
  border: 1px solid rgb(175, 175, 175);
  background: rgb(255, 255, 255);
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 6px 0;
  cursor: pointer;
}

.card-connect-shop-open {
  width: 100%;
  min-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  animation: smooth-open 0.5s;
}

@keyframes smooth-open {
  from {
    height: 0px;
  }
  to {
    height: 170px;
  }
}

.connect-shop-modal .ant-modal-content{
    background: #f7f7f7;
    border-radius: 8px;
}
.connect-shop-modal .ant-modal-body{
    background: #f7f7f7;
    border-radius: 8px;
}

@media screen and (max-width: 1100px) {
  .card-connect-shop {
    width: 300px;
  }
}
